.messageComposerWrapper,
.single-composer-body {
	.attachmentsField {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
		border-left: 1px solid rgba(25, 37, 46, 0.16);
	}
	.attachmentsFieldMinimized {
		width: 48px;
		position: relative;
		border-left: 1px solid rgba(0, 0, 0, 0.16);
		cursor: pointer;

		&:hover {
			background: rgba(22, 31, 41, 0.08);
		}
	}
	#extendFiles {
		width: 24px;
		height: 24px;
		position: absolute;
		top: 16px;
		left: 12px;
	}
	#attachmentIcon {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		position: absolute;
		top: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		left: 8px;
		svg {
			position: absolute;
		}
	}
	#fieldTitle {
		display: inline-block;
		height: 24px;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 19px;
		line-height: 24px;
		color: rgba(22, 33, 41, 0.92);
	}
	.attachments-list {
		width: 100%;
		padding-bottom: 12px;
		transition: opacity linear 0.2s;
		border-bottom: 1px solid rgba(25, 37, 46, 0.16);
	}

	#noFilesUploaded {
		top: 175px;
		width: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
		text-align: center;
		transition: opacity linear 0.2s;
	}
	#noFilesTitle {
		display: inline-block;
		height: 24px;
		font-family: Roboto !important;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: #19252e;
	}

	.file-drop {
		width: 299px;

		.file-drop-target {
			position: relative;
			height: 100%;
			width: inherit;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			&.file-drop-dragging-over-frame {
				outline: 2px dashed #19252e;
				outline-offset: -8px;
				border-radius: 4px;
				background: rgba(255, 255, 255, 0.6);
				box-shadow: none;
			}
		}

		&__message {
			width: 100%;
			font-family: Roboto;
			font-weight: bold;
			font-size: 24px;
			line-height: 32px;
			color: #19252e;
			position: absolute;
			text-align: center;
			top: 50%;
			left: 0;
		}

		&__size-info {
			width: 100%;
			padding: 12px 0px 16px 0px;
			display: flex;
			margin-top: auto;
			font-family: Roboto;
			font-size: 12px;
			font-weight: 400;
			line-height: 14px;
			letter-spacing: 0.35px;
			justify-content: center;
			color: #596269;
		}
	}
}

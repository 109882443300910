.settingsCollapseChoice {
	border: none;
	margin-top: 5px;
	margin-bottom: 11px;
	height: 24px;
	border-bottom: 1px solid rgba(22, 31, 41, 0.16);
	background: #ffffff;
	width: 284px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	display: inline-block;
}
#root .settingCollpase .dropdown {
	display: inline-block !important;
	height: 24px !important;
	top: 12px !important;
	width: fit-content;
	z-index: auto !important;
}
.settingCollpase {
	.dropdown-menu {
		transform: inherit !important;
		left: -284px !important;
		max-height: 30vh;
		overflow-y: scroll;
		overflow-x: hidden;
		top: 20px !important;
		border-radius: 0px !important;
		border: none !important;
		box-shadow: 0 0 8px #00000016, 8px 0 16px -2px #00000016, 8px 0 6px -4px #00000024;
		-ms-overflow-style: none;
		scrollbar-width: thin;
		scrollbar-color: rgba(0, 0, 0, 0.16);
			scrollbar-width: thin;
			&::-webkit-scrollbar {
				display: block;
				width: 6px;
			  }
			&::-webkit-scrollbar-track {
			box-shadow: unset;
			background-color: white;
			}
			&::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: rgba(0, 0, 0, 0.16);
			}
		.dropdown-item {
			width: 320px;
			height: 40px;
			padding-left: 12px !important;
			font-style: normal !important;
			font-weight: normal !important;
			font-size: 16px !important;
			line-height: 24px !important;
			color: #000000;
			&:hover {
				background: rgb(235, 235, 235) !important;
			}
		}
	}
	.btn-secondary {
		box-shadow: none !important;
		display: flex;
		background-color: white !important;
		border: none !important;
	}
}
.settingCollpase .btn-secondary.focus,
.btn-secondary:focus {
	box-shadow: none !important;
	background-color: white !important;
	border: none !important;
}
.settingsCollapseTitle {
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	width: 320px;
	color: #000000;
}
.settingsCollapseInfo {
	width: 320px;
	display: flex;
	flex-direction: row;
	align-items: center;
	svg {
		position: relative;
	}
	.settingsCollapseTitle {
		margin-right: 5px;
		width: auto;
	}
}
.activeItem {
	background: rgba(245, 141, 0, 0.32);
}

.mail-confirm-modal{
    position: absolute;
    top: 16px;
    width: 55%;
    max-width: 550px;
    min-width: 450px;
    left: 50%; 

    transform:translateX(-50%);
    height: 112px;
    z-index: 200;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 4px 8px -4px rgba(25, 37, 46, 0.36), 0px 8px 8px -2px rgba(25, 37, 46, 0.16);
    filter: drop-shadow(0px 0px 8px rgba(25, 37, 46, 0.12));
    display: flex;
    flex-direction: column;

    .btn{
        border: 0.5px solid rgba(22, 33, 41, 0.64);
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-transform: none;
        color: rgba(22, 33, 41, 0.92);
        white-space: nowrap;
        padding: 3px 20px;
    
        &.btn-colored{
            background: rgba(245, 141, 0, 0.6);
            border: 0.5px solid rgba(223, 128, 0, 0.28);
        }
    
        &:focus,
        &:active{
            outline: none;
            box-shadow: none;
        }
    
        &:nth-of-type(2){
            margin-left: 12px;
        }
        &:nth-of-type(3){
            margin-left: auto;
        }
    }

    &-header{
        width:100%;
        height: 56px;
        padding-left: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;

        p{
            margin: 0;
            margin-left: 8px;
            font-family: PT Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
        }
    }

    &-body{
        width: 100%;
        height: 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 44px;
        padding-right: 24px;
    }
}





.webpush-bar{
    height: auto;
    max-height: 100vh;
    width: 368px;    
    position: absolute;
    right: 0;
    top: 0;
    background: transparent;
    z-index: 999999999;
    // pointer-events: none;
    padding-top: 32px;
    padding-bottom: 100px;
    // -ms-overflow-style: none;  /* IE and Edge */
    // scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        width: 4px;
        right: 2px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color:rgba(0, 0, 0, 0.2);
        cursor: pointer !important;
        z-index: 2;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    // overflow-y: scroll;
}

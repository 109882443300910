.mail-management-panel {
	flex-direction: row;
	display: flex;
	width: 100%;
	overflow: hidden;

	.emails-amount {
		font-size: 18px;
		text-transform: capitalize;
		color: rgba(22, 33, 41, 0.92);
		min-width: unset;
		overflow: hidden;

		.emails-amount__text{
			overflow: hidden;
			text-overflow: ellipsis;
		}

		svg {
			transition: transform 0.2s;
			flex-shrink: 0;
		}
	}

	.manage-options {
		margin-left: auto;
		display: flex;
		align-items: center;
		align-items: flex-start;
	}
}

.listbar {
	&__header {
		.folder-name {
			position: relative;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-right: 12px;
			font-family: Roboto;
			font-weight: 500;
			font-size: 19px;
			line-height: 40px;
			color: #19252e;
		}

		.folder-name-container {
			display: flex;
			flex-grow: 1;
			width: 0;
		}
	}

	&__default-header {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
	}
}

.single-composer-header {
	width: 100%;
	height: 56px;
	background-color: #fff;
	border-bottom: 1px solid rgba(0, 0, 0, 0.16);
	padding: 0 10px 0 24px;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-shrink: 0;
	p {
		flex-grow: 1;
		margin-bottom: 0;
		height: 24px;
		position: relative;
		font-family: Roboto !important;
		font-style: normal;
		font-weight: 500;
		font-size: 19px;
		line-height: 24px;
		color: rgba(22, 33, 41, 0.92);
	}
}
.closeCompose, .minimizeCompose {
	width: 25px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}
.minimizeCompose {
	margin-right: 20px;
}

.account-settings {
	padding: 24px 32px;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
}

$bgControlsHover: rgba(255, 150, 46, 0.4);
$bg: rgba(255, 150, 46, 0.4);
$bgHover: rgba(255, 150, 46, 0.56);
$border: #f49200;

.accountSettings,
.account-settings {
	.accountConnectionError {
		margin-top: 12px;
	}

	.autorespondTextarea {
		resize: none;
		width: 374px;

		height: 120px;
		left: 312px;
		top: 509px;
		background-color: white;
		border: 1px solid rgba(25, 37, 46, 0.08);
		border-radius: 4px;

		&:focus-visible {
			outline: none;
		}

		&:disabled {
			background: rgba(25, 37, 46, 0.08);
		}
	}

	&::-webkit-scrollbar {
		display: none;
	}

	.respLengthError,
	.forwardError {
		color: red;
		font-size: 12px;
	}

	.quota {
		&-label {
			font-family: 'Roboto';
			font-size: 16px;
			line-height: 24px;
			color: #19252e;
		}

		&-progress {
			&-root {
				width: 244px;
				height: 12px;
				background: rgba(25, 37, 46, 0.08);
				border-radius: 2px;
				margin-top: 8px;
			}

			&-bar {
				background: #44a1f0;
				border: 1px solid #298de1;
				border-radius: 2px;

				&.warning {
					background: rgba(255, 150, 46, 0.56);
					border: 1px solid rgba(25, 37, 46, 0.08);
				}

				&.danger {
					background: #f2969e;
					border: 1px solid #ec858d;
				}
			}
		}
	}
}

.forwardSubSettings {
	height: 100%;
	width: 100%;
	max-width: 640px;

	display: flex;
	flex-direction: column;
	// border-bottom: 1px solid rgba(25, 37, 46, 0.16);

	.forwardInputWrap {
		display: flex;
		flex-direction: column;

		.forwardSettingsError {
			margin-left: 13px;
			color: red;
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
		}

		.forwardSettingsRoot {
			input {
				padding: 0px 12px;
				gap: 10px;
				width: 348px;
				height: 32px;
				border: 1px solid rgba(25, 37, 46, 0.4);
				border-radius: 4px;
			}
		}

		.forwardSettingsDisabled {
			input {
				background: rgba(25, 37, 46, 0.08);
				border: 1px solid rgba(25, 37, 46, 0.08);
				border-radius: 4px;
			}
		}
	}
}

.filtersSubSettings {
	height: auto;
	width: 100%;
	max-width: 700px;
	display: flex;
	flex-direction: column;
	gap: 2rem;

	.filterButtonRoot {
		width: max-content;
		height: 32px;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: hsla(206, 30%, 14%, 1);

		background-color: transparent;
		border: 1px solid hsla(206, 30%, 14%, 0.08);
		border-radius: 4px;

		&:hover {
			background-color: hsla(206, 30%, 14%, 0.08);

		}
	}

	.filterAddIcon {
		width: 24px;
		height: 24px;
	}

	.filter-entity_dragndrop {
		height: 40px;
		width: 24px;
		display: flex;
		align-items: center;
		position: absolute;
		transform: translate(-100%);
	}

}

.profilesSubSettings {
	min-height: 168px;
	height: auto;
	width: 100%;
}

.personalSubSettings {
	max-width: 640px;
	min-height: 168px;
	height: auto;
	width: 100%;
	margin-bottom: 32px;
}

.autoresponderSubSettings {
	min-width: 640px;
	height: 100%;
	display: flex;
	flex-direction: column;

	&.external {
		padding-bottom: 40px;
		margin-bottom: 40px;
		border-bottom: 1px solid rgba(25, 37, 46, 0.16);
	}
}

.signaturesHraderRow {
	margin-bottom: 56px;
}

.foldersSubSettings {
	max-width: 640px;
	height: auto;
	width: 100%;
	padding-bottom: 40px;
	border-bottom: 1px solid rgba(25, 37, 46, 0.16);
	margin-bottom: 40px;

	.settingsButton {
		border: 1px solid rgba(22, 31, 41, 0.6);

		div {
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 20px;
			color: rgba(22, 31, 41, 0.6);
		}
	}
}

.subSettingsBoldHint {
	position: relative;
	display: inline-block;
	height: 24px;
	top: -7px;
	margin-left: 8px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
}

.subSettingsInfo {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	margin-top: 6px;
	position: relative;
	width: 100%;
	height: 20px;
	color: rgba(22, 31, 41, 0.6);
}

.addHeight {
	position: relative;
	width: 100%;
}

.horizontalSeparator {
	font-size: 16px;
	line-height: 24px;
	flex-shrink: 0;

	&.disabled {
		opacity: 0.4;
	}
}

.avatarRow {
	display: flex;
	flex-direction: row;
	align-items: baseline;

	.avatarChangeButtons {
		margin-left: 24px;
	}
}

.signaturesAddRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 24px;

	.subSettingTitle {
		font-family: Roboto;
		margin-top: 0 !important;
	}

	.signatureAddIcon {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}
}

.signatureSwitchRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 20px;
}

.avatarInSettings {
	width: 120px;
	height: 120px;
	position: relative;
	margin-top: 16px;
	display: inline-block;

	img {
		width: 120px;
		height: 120px;
		border-radius: 50%;
	}
}

.changeAvatarWrapper {
	height: 32px;
	display: inline-block;

	&.settingsButton {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 101px;
		height: 32px;
		border: 1px solid rgba(25, 37, 46, 0.4);
		border-radius: 4px;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0.35px;
		color: #19252e;
	}

	&.cancelSettings {
		font-family: 'Roboto';
		border: none !important;
	}
}

.croppieInAvatar {
	width: 418px;
	height: 498px;
	margin-top: 68px;
	position: absolute;
	margin-left: 16px;
}

.changeAvatarTitle {
	font-family: Roboto;
	position: absolute;
	height: 24px;
	top: 16px;
	left: 16px;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: rgba(0, 0, 0, 0.8);
}

.MuiSlider-thumb {
	width: 20px !important;
	height: 20px !important;
	border-radius: 50% !important;
	background: #fff !important;
	border: 2px solid #f58d00 !important;

	&.MuiSlider-active {
		box-shadow: initial !important;
	}
}

.MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
	box-shadow: initial !important;
}

.MuiSlider- {
	box-shadow: initial !important;
}

.signaturesSubHeader {
	max-width: 300px;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: rgba(22, 31, 41, 0.6);
}

.addSignatureButton {
	color: #19252e;
	min-width: 207px !important;
	height: 32px !important;
	display: flex;
	text-transform: none !important;
	border: 1px solid rgba(25, 37, 46, 0.4) !important;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 4px 16px 4px 12px;

	:first-child {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
	}
}

.signatureDialogFooter {
	margin-top: 15px;
	display: flex;
	flex-direction: row;
	align-items: center;

	.saveSettings {
		margin-left: 16px;
	}

	.subSettingsNotificationsHint {
		float: left;
		margin-right: auto;
		top: 16px;
		margin-left: 8px;
	}

	.cancelSettings {
		position: relative;
	}

	.subSettingsNotificationsCheckbox {
		display: flex;
		margin-top: -1px;
		margin-left: 12px;
		cursor: pointer !important;
	}
}

.changeAvatarFooter {
	width: 100%;
	position: absolute;
	bottom: 0;
	display: flex;
	align-items: center;
	left: 0;
	height: 56px;

	input[type='file'] {
		display: none;
	}
}

.labelAvatarUpload {
	border: 1px solid #ccc;
	position: absolute;
	right: 12px;
	bottom: 12px;
	display: inline-flex;
	height: 32px;
	cursor: pointer;
	border: 1px solid #f49200;
	background: #ffffff;
	border-radius: 2px;
	padding-right: 12px;
	padding-left: 12px;
	padding-top: 5px;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 20px;
	color: #f49200;
	margin-bottom: 0 !important;
}

.deleteAvatar,
.deleteAccount {
	padding: 16px 0 0 24px;
	margin-right: auto;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;

	color: rgba(0, 0, 0, 0.8);
}

.deletePaper {
	// border-radius: 0 !important;
	border-radius: 8px;

	.deleteDialog {
		width: 462px;
		height: 232px;
		display: flex;
		flex-direction: column;
		border-radius: 0;

		&.avatar {
			height: 136px;
			padding-top: 4px;
		}

		.deleteTitle {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
			color: rgba(0, 0, 0, 0.8);
		}

		.deleteNote {
			font-family: 'PT Sans';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: #000000;
		}
	}

	.deleteFooter {
		width: 100%;
		padding: 0px 0px 28px 24px !important;
		position: relative;
		margin-top: auto;
		height: 56px;
		justify-content: flex-start;
		display: flex;
		align-items: center;

		.cancelButton,
		.saveButton {
			color: rgba(0, 0, 0, 0.87);
			text-transform: none;
			width: 90px;
			height: 32px;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
		}

		.saveButton {
			color: #fff;
			background: #e2333a;
			border: 1px solid rgba(25, 37, 46, 0.08);
			border-radius: 4px;

			&:hover {
				color: #fff;
				background: #bb1b1f;
				box-shadow: 0 0 0px rgba(0, 0, 0, 0);
			}
		}

		.cancelButton {
			margin-left: 12px !important;
			color: #19252e;
			background: #ffffff;
			border: 1px solid rgba(25, 37, 46, 0.4);

			&:hover {
				color: #19252e;
				background: rgba(25, 37, 46, 0.08);
				border: 1px solid rgba(25, 37, 46, 0.08);
			}
		}
	}
}

.folderDialogFooter {
	width: 100%;
	position: relative;
	align-items: center;
	display: flex;
	padding-left: 24px;
	padding-bottom: 24px;
	margin-top: 35px;
	gap: 8px;
}

.modalHeader {
	height: auto;
	min-height: 56px;
	max-height: 60px;
	padding-left: 16px;
	padding-top: 16px;
	width: 100%;
	overflow: hidden;
	max-width: calc(100% - 24px);
	font-family: 'Roboto';
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: rgba(0, 0, 0, 0.8);
}

.folderDialogCollapse {
	.settingsCollapseChoice {
		width: 390px !important;
	}

	.settingCollpase {

		.settingsCollapseTitle,
		.settingsCollapseChoice {
			font-family: 'Roboto';
		}

		.dropdown {
			svg {
				top: 0 !important;
				left: 0 !important;
				position: relative;
			}

			button {
				width: 24px !important;
				padding: 0;
			}
		}

		.dropdown-item {
			&:focus {
				border: none !important;
				background: rgba(245, 141, 0, 0.32) !important;
				outline: none !important;
			}

			&:active {
				border: none !important;
				background: rgba(245, 141, 0, 0.32) !important;
				outline: none !important;
			}
		}

		.dropdown-menu {
			left: -402px !important;
			top: 23px !important;
			width: 426px !important;
			min-width: 150px !important;

			.dropdown-item {
				width: 426px !important;
				margin-right: 0 !important;
			}
		}
	}

	.cantMove {
		height: 18px;
		position: relative;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		color: #e24141;
	}
}

#root .folderDialogCollapse .settingCollpase .dropdown {
	margin-left: 12px !important;
	top: 7px !important;
	position: relative !important;
	width: fit-content;
	z-index: auto !important;
}

.deleteFolderText {
	height: 96px;
	width: 100%;
	padding-left: 12px;
	padding-right: 12px;
	display: table-cell;
	vertical-align: middle;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}

.deleteFilterText {
	height: 72px;
	width: 100%;
	padding-left: 24px;
	padding-right: 12px;
	display: table-cell;
	vertical-align: middle;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}

.actionButtons {
	width: fit-content;
	float: right;
	margin: 12px 14px 12px 0px;
}

#unsubscribeButton {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
	margin-bottom: 15px;
	padding: 4px 0px;
	gap: 10px;
	width: 203px;
	height: 32px;
	border: 1px solid #e2333a;
	border-radius: 4px;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.35px;
	color: #e2333a;
	background: #fff;
}

.set_standard_folders {
	border: 1px solid rgba(25, 37, 46, 0.4) !important;
	outline: none !important;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 4px 12px;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	background-color: transparent;
	color: rgba(22, 31, 41, 0.6);
}

.select-menu {
	&__paper {
		scrollbar-color: rgba(0, 0, 0, 0.16);
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			display: block;
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			box-shadow: unset;
			background-color: white;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: rgba(0, 0, 0, 0.16);
		}
	}

	&__list-item {
		&.Mui-selected {
			background-color: $bg;
		}

		&:hover,
		&.Mui-selected:hover {
			background-color: $bgHover;
		}
	}
}

.folderdialogRoot {
	z-index: 1100;

	.folderdialogContainer {
		.folderdialogPaper {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 426px;
			padding: 16px;
			padding-top: 24px;

			.folderDialogFooter {
				justify-self: flex-end;
				padding: 0;

				.cancelSettings {
					margin-left: 0px;
				}
			}

			.TandISettings {
				width: 100%;
				flex: 1;

				.InputWrapForTandI {
					width: 100%;

					.folderNameInput {
						width: 100%;
						padding-left: 10px;
						font-family: Roboto;
						font-size: 16px;
						border-color: rgba(25, 37, 46, 0.4);
					}
				}
			}

			.folderDialogCollapse {
				flex: 1;

				.folderFormRoot {
					width: 100%;

					.foldersSelect {
						height: 32px;

						.folderDialogSelect {
							height: 100%;
							padding: 0 32px 0 10px;
							display: flex;
							align-items: center;

							.svgIcon {
								margin-right: 5px;
							}
						}
					}
				}
			}
		}
	}
}
#saving-dialog-title {
    h2 {
        font-family: "Roboto" !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 20px !important;
        color: rgba(22, 33, 41, 0.92) !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 24px;
        cursor: default;
    }

    padding:16px 20px 0px 24px;
}

#saving-dialog-content {
    padding: 18px 24px 8px 24px;

    p {
        margin: 0;
        cursor: default;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
    }

    #saving-dialog-footer {
        height: 56px;
        width: 100%;
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;

        button {
            height: 32px;
            padding: 4px 16px;
            border-radius: 4px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            // line-height: 24px;
            letter-spacing: 0.35px;
            background: white;
            cursor: pointer;

            &:disabled {
                opacity: 0.4;
            }
        }

        button:nth-of-type(1) {
            background: rgba(255, 150, 46, 0.56);
            color: #19252E;
            border: 1px solid rgba(25, 37, 46, 0.08);
        }

        button:nth-of-type(2) {
            border: 1px solid rgba(25, 37, 46, 0.4);
            color: #19252E;
            margin-left: 12px;
        }

        button:nth-of-type(3) {
            margin-left: auto;
            color: #D43A3E;
            font-weight: 400;
            border: 1px solid #D43A3E;
        }
    }
}

div[aria-describedby='saving-dialog-description'] {
    border-radius: 8px;
}
.psnCheckboxRoot {

  .MuiFormControlLabel-root{
    margin-left: 0;
    margin-bottom: 0;
    &:not(.Mui-disabled) {
      &:hover {
        .emptyCheck  {
          background: rgba(25, 37, 46, 0.08);
          border: 2px solid rgba(25, 37, 46, 0.72);
        }

        & .checked {
          background-color: rgba(255, 150, 46, 0.72);
        }
      }
    }
  }

  & .MuiFormControlLabel-label {
    margin-left: 8px;
  }

  & .MuiCheckbox-root{
    padding: 0;
    border-radius: 4px;
  }

  & .Mui-disabled {
    & .emptyCheck {
      background: rgba(25, 37, 46, 0.08);
      border-color: rgba(25, 37, 46, 0.08);;
    }

    & .checked {
      background-color: rgba(25, 37, 46, 0.14);
      border: 1px solid rgba(25, 37, 46, 0.12);
      opacity: 0.6;
    }
  }

  & .checkbox {
    width: 17px;
    height: 17px;

    &.emptyCheck {
      border: 2px solid rgba(25, 37, 46, 0.56);
      border-radius: 4px;
    }

    &.checked {
      background-image: url('../../../assets/check.svg?inline');
      background-repeat: no-repeat;
      background-position: center;
      background-color: rgba(255, 150, 46, 0.56);
      border: 1px solid rgba(25, 37, 46, 0.06);
      border-radius: 4px;
      transition: opacity 0.5s ease-in;
      opacity: 1;
    }
  }



}

.paginationFooter {
    /* flex-wrap: wrap; */
    /* justify-content: flex-end; */
    /* gap: 8px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 55px;
    position: absolute;
    width: 100%;
    bottom: 0px;
    /* background-color: #ffffff; */
    border-top: 1px solid rgba(25, 37, 46, 0.16);
}

.paginationFooter * {
    cursor: pointer !important;
}

.activePage {
    width: 24px;
    height: 24px;
    position: relative;
}

.pageNumber {
    position: relative;
    height: 24px;
    font-family: Roboto !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #19252E;
}

.pageIcon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    /* margin-top: 16px; */
}

.pageIcon:hover {
    background-color: rgba(1, 1, 1, 0.1);
}

.pageIconActive {
    background: url('./activePage.svg?inline') !important;
}

#firstPage {
    width: 24px;
    height: 24px;
    position: relative;
    margin-right: 8px;
    cursor: pointer;
    display: flex;
}

#firstPage svg {
    position: relative;
}

#previousPage {
    width: 24px;
    height: 24px;
    position: relative;
    cursor: pointer;
    display: flex;
}

#previousPage svg {
    position: relative;
}

#nextPage {
    width: 24px;
    height: 24px;
    position: relative;
    cursor: pointer;
    display: flex;
}

#nextPage svg {
    position: relative;
}

#lastPage {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    position: relative;
    cursor: pointer;
    display: flex;
}

#lastPage svg {
    position: relative;
}
.file-card {
	width: 156px;
	height: 108px;
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	position: relative;
	border: 1px solid rgba(0, 0, 0, 0.16);
	border-radius: 4px;
	margin-right: 16px;
	margin-bottom: 12px;
	cursor: pointer;

	.file-card {
		&__body {
			width: 100%;
			position: relative;
			display: flex;
		}
	}

	.fileName {
		width: auto;
		overflow: hidden;
		word-break: break-all;
		font-family: Roboto !important;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 0.35px;
		color: #19252e;
	}

	.fileCardFooter {
		width: 100%;
		position: relative;
		border-radius: 0px 0px 0px 4px;
		padding-left: 8px;
		padding-right: 8px;
		background: #ffffff;
	}

	.svgForFooter {
		position: absolute;
		right: -1px;
		bottom: -2px;
	}
}

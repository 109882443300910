.folder-line {
	&__trigger {
		display: flex;
		width: 0;
		max-width: 560px;
		height: 40px;
		align-items: center;
		opacity: 0;
		transition: .2s;
	}

	&:hover .folder-line__trigger, &__trigger_dragged {
		width: 24px;
		opacity: 1;
	}

	&__body {
		width: 100%;
		height: auto;
		min-height: 40px;
		max-height: 100px;
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-right: 12px;
		border-radius: 4px;
	}

	&:hover .folder-line__body, &__body_dragged {
		background: rgba(22, 31, 41, 0.08);
	}

	&__body_dragged {
		cursor: grabbing;
	}

	.svgIcon {
		flex-shrink: 0;
		cursor: pointer;
	}

	&__folder-trash, &__folder-pencil {
		visibility: hidden;
		margin-left: 12px;
	}

	&:hover .folder-line__folder-trash, &__folder-trash_dragged, 
	&:hover .folder-line__folder-pencil, &__folder-pencil_dragged {
		visibility: visible;
	}

	&__name {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 20px;
		position: relative;
		margin-left: 8px;
		max-height: 100px;
		overflow-y: hidden;
		overflow-x: hidden;
		color: #000000;
		flex-grow: 1;
	}
}

.eula-wrap {
	background: #FFFFFF;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: row;
	justify-content: center;
	overflow: auto;

	.eula-body {
		padding-top: 25px;
		min-width: 888px;
		height: 100%;
		flex-direction: column;
		display: flex;
	}
}
.eula-sub-header {
	display: flex;
	width: 100%;
	height: 160px;
	margin-left: -60px;
	flex-direction: row;
	align-items: center;
	& > div {
		margin-left: 32px;
		width: 100%;
	}
	.eula-locale {
		height: 36px;
		margin-top: 16px;
	}
}
.eula-sub-header-body {
	display: flex;
	flex-direction: column;

	& > h2 {
		font-family: Roboto !important;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 28px;
		letter-spacing: 0.25px;
		color: #19252E;
	}
	& > h3 {
		margin-bottom: 16px;
		font-family: Roboto !important;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		color: #596269;
	}
}
.eula-main {
	height: 100%;
	padding-left: 112px;
	display: flex;
	flex-direction: column;
}
.eula-footer {
	margin-top: auto;
	width: 100%;
	height: 22px;
	padding-bottom: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	& > p {
		margin: 0;
		margin-right: 16px;
		font-family: Roboto !important;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 0.35px;
		color: #7E858A;
	}
	& > a {
		margin: 0;
		margin-right: 16px;
		font-family: Roboto !important;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		cursor: pointer !important;
		letter-spacing: 0.35px;
		color: #7E858A !important;
		&:hover {
			text-decoration: underline !important;
		}
	}
}
.eula-update-date {
	margin-bottom: 16px;
	height: 24px;
	font-family: Roboto !important;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: #596269;
}
.eula-text {
	width: 760px;
	max-width: 760px;	
	overflow-x: hidden;
	margin: 0;
	font-family: Roboto !important;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: #19252E;	
}

.thumb-vertical {   
    cursor: pointer !important;
    border-radius: inherit;
	width: 4px !important;  
	background: rgba(25, 37, 46, 0.16);
		&:hover {
			background: rgba(25, 37, 46, 0.16);
		} 
}

.eula-accept-block {
	margin-top: 16px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	& > p {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		margin: 0;
		margin-left: 4px;
		color: #2B363F;
	}
}
.eula-buttons {
	height: 40px;
	margin-top: 16px;
	margin-bottom: 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	& > button {
		&:nth-of-type(1) {
			background: rgba(68, 187, 255, 0.56);
			font-family: Roboto !important;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			padding: 4px 16px;
			letter-spacing: 0.35px;
			color: #19252E;
			border: 1px solid rgba(25, 37, 46, 0.08);
			border-radius: 4px;
		}
		&:nth-of-type(1):disabled {
			background: rgba(25, 37, 46, 0.08);
			color: #A3A8AB;
		}
		&:nth-of-type(2) {
			height: 32px;
			padding: 4px 16px;
			margin-left: 20px;
			background: #fff;
			border: none;
			cursor: pointer !important;
			font-family: Roboto !important;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 0.35px;
			color: #007CC3;
		}
	}
}
.press-box {
	h1 {
		font-size: 30px;
		line-height: 34px;
		margin: 0px;
		font-weight: normal;
	}
	h2 {
		font-size: 24px;
		margin: 0 0 10px;
		text-align: left;
		font-weight: normal;
	}
	em {
		&.date {
			display: block;
			color: #9d9d9d;
			margin: 0 3px 6px;
		}
	}
	a {
		&:hover {
			text-decoration: none;
		}
	}
}
.press-box a,
.press-box a:visited {
	color: rgb(0, 153, 243);
	text-decoration: underline;
}

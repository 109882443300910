.extended-search {
	box-shadow: 0px 0px 8px rgba(25, 37, 46, 0.08), 0px 8px 8px -4px rgba(25, 37, 46, 0.08);
	border-radius: 12px;
	margin: 8px 8px 16px 8px;

	&__header {
		width: 100%;
		height: 40px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0 8px 0 16px;
	}

	&__header-title {
		overflow: hidden;
		text-overflow: ellipsis;
		font-family: 'Roboto';
		font-style: normal;
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
		margin-right: 12px;
	}

	&__body {
		width: 100%;
		padding: 0 16px 16px 16px;
		display: flex;
		flex-direction: column;
		margin-top: 8px;
	}

	&__filter-line {
		width: 100%;
		height: 64px;
		position: relative;
		padding: 0;
		margin-bottom: 4px;

		.extended-search__chip {
			.MuiChip-label {
				width: 0;
			}
		}
	}

	&__filter-line-header {
		font-family: 'Roboto';
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		color: rgba(25, 37, 46, 0.72);
	}

	&__autocomplete-input {
		.MuiOutlinedInput-root {
			padding: 0;
			padding-left: 12px;
		}
	}

	&__icon-wrap {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		transition: all 0.2s;
		cursor: pointer;

		&:hover {
			background: rgba(25, 37, 46, 0.08);
		}
	}

	&__attachments {
		display: flex;
		margin-top: 12px;
		width: fit-content;
		flex-direction: row;
		align-items: center;
		height: 24px;
		cursor: pointer !important;
	}

	&__search-button {
		margin-top: 20px; // 12px если вернуть аттачи в форму
		width: fit-content;
		background: rgba(255, 150, 46, 0.56);
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0.35px;
		color: #19252e;
		border: 1px solid rgba(25, 37, 46, 0.08);
		text-transform: capitalize;

		&:hover {
			background: rgba(255, 150, 46, 0.4);
		}

		&:disabled {
			background-color: rgba(255, 150, 46, 0.4);
			color: rgb(25 37 46 / 40%);
		}
	}

	&__error-text {
		color: red;
		font-family: Roboto;
		font-size: 14px;
		margin-top: 15px;
	}

	.psnCheckboxRoot .MuiFormControlLabel-root {
		margin: 0;
	}

	.date-picker-wrapper {
		display: flex;
		flex-direction: column;
		width: 0;
		flex-grow: 1;
	}
}

.mail-mark {
  width: 100%;
  
  svg {
    flex-shrink: 0;
  }

  .mail-mark__label {
    font-size: 14px;
    font-weight: 400;
    margin-left: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.single-view-wrap {
	height: 100%;
	width: 100%;
	background: rgba(22, 31, 41, 0.24);
	display: flex;
}
.single-view {
	height: 100%;
	overflow-y: scroll;
	width: 900px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

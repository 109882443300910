.SharingPaper {
	border-radius: 8px;
	width: 500px;
	height: 431px;
	box-shadow: 0px 8px 8px -4px rgba(25, 37, 46, 0.08), 0px 0px 8px 0px rgba(25, 37, 46, 0.08);
	padding: 24px 16px;

	&_title {
		color: #19252e;
		padding: 0;
		margin: 4px 0;
		/* h2 */
		font-family: Roboto;
		font-size: 19px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px; /* 126.316% */
	}

	&_footer {
		padding: 0;
		height: 32px;
		margin-top: auto;
		justify-content: flex-start;
	}

	&_cancel {
		border-radius: 4px;
		padding: 3px 12px;
		border: 1px solid rgba(25, 37, 46, 0.4);
		color: #19252e;
		/* Specific/button_normal */
		font-family: Roboto;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px; /* 171.429% */
		letter-spacing: 0.35px;
		outline: none;
	}

	&_userSection {
		width: 100%;
		height: 64px;
		margin-top: 22px;
		padding-top: 10px 
	}

	&_editUser {
		margin-top: 26px;
		padding-bottom: 4px;
		display: flex;
		flex-direction: column;
		& > p {
			margin: 0;
			margin-bottom: 4px;
			color: var(--basic-light-fill-72, rgba(25, 37, 46, 0.72));
			font-family: Roboto;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px; /* 171.429% */
		}
	}

	&_folderSection {
		margin-top: 24px;
		h3 {
			color: #19252e;
			margin: 6px 0;
			font-family: Roboto;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 125% */
		}
		h4 {
			height: 28px;
			color: var(--basic-light-fill-56, rgba(25, 37, 46, 0.56));
			font-family: Roboto;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.35px;
			margin-top: 10px;
			margin-bottom: 9px;
		}
	}

	&_folderLabel {
		color: var(--basic-light-fill-56, rgba(25, 37, 46, 0.56)) !important;
		font-family: Roboto;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		margin-left: -14px;
		margin-top: 8px;
		line-height: 24px;
	}

	&_AllowCheckbox{
		margin-top: 32px;
	}
}

.SharingModal {
	&_folderOption {
		height: 40px;
		padding: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		color: #19252e;
		/* Body2/regular */
		font-family: Roboto;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px; /* 171.429% */

		& > p {
			margin: 0;
			margin-left: 4px;
		}

		&_disabled {
			opacity: 0.5;
			pointer-events: none;
		}
	}
}

.folders-select {
	color: #19252e;
	width: 100%;
	//   padding: 4px 12px;
	height: 32px;
	/* Body2/regular */
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 171.429% */
}

.SharingSelectMenuBox > div.MuiPaper-root {
	&::-webkit-scrollbar {
		width: 14px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: transparent;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		border: 4px solid rgba(0, 0, 0, 0);
		background-clip: padding-box;
		border-radius: 9999px;
		// background-color: #AAAAAA;

		// border: 4px solid rgba(0, 0, 0, 0);
		// background-clip: padding-box;

		background-color: var(--black-fill-16, rgba(25, 37, 46, 0.16));
	}

	/* Handle on hover */
	// &::-webkit-scrollbar-thumb:hover {
	//   background: red;
	// }
}

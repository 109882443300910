.single-composer-body {
	#subjectLine {
		width: 100%;		
		height: 56px;
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	#subjectInput {	
		width: 100%;		
		height: 24px;
		border: none;
		border-bottom: 1px solid rgba(25, 37, 46, 0.4);
		margin-left: 24px;
		font-family: Roboto !important;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		outline: none;
		color: #19252E;
		&::placeholder {
			font-family: Roboto !important;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			color: #596269;
		}
		&:focus {
			outline: none;
		}
	}
	#mailPriority {
		width: 24px;
		height: 24px;
		margin-left: auto;
	}
	#priorityList {
		width: 24px;
		height: 24px;
	}
	.PriorityDropDown {
		width: 24px;
		height: 24px;
		margin-right: 47px;
		button {
			width: 24px;
			height: 24px;
			border: none;
			padding: 0;
		}
		.dropdown-menu {
			z-index: 10020 !important;
			width: 222px !important;
			height: 176px !important;
			position: absolute;
			top: -4px !important;
			box-shadow: 0 0 8px #00000016, 8px 0 16px -2px #00000016, 8px 0 6px -4px #00000024 !important;
		}
		.dropdown-item {
			width: 100% !important;
			height: 40px !important;
			padding-left: 12px;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
		}
		.btn-secondary {
			padding: 0;
			box-shadow: none !important;
			background-color: white !important;
			border: none !important;
			padding: 0;
		}
	}
	.dropdown-menu {
		.dropdown-item {
			&:active {
				background-color: rgb(235, 235, 235) !important;
				color: black !important;
			}
		}
	}
}
.single-composer-body .PriorityDropDown .btn-secondary.focus,
.single-composer-body .btn-secondary:focus {
	box-shadow: none !important;
	background-color: white !important;
	border: none !important;
}

.save-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0px;
  margin-top: auto;

  button {
    height: 32px;
    padding: 4px 12px;
    background: rgba(255, 150, 46, 0.56);
    border: 1px solid rgba(25, 37, 46, 0.08);
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.35px;
    color: #19252e;
    border-radius: 4px;

    &:hover {
      background-color: #ec8000;
    }
    &:active {
      background: rgba(25, 37, 46, 0.08);
    }
    &:disabled {
      background: rgba(25, 37, 46, 0.08);
    }
    &:focus {
      background: rgba(25, 37, 46, 0.08);
    }
  }
}

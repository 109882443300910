.left-sidebar {
	.folder {
		&.Mui-selected {
			background: rgba(25, 37, 46, 0.16);

			&:hover {
				background: rgba(25, 37, 46, 0.16);
			}
		}
		&:hover,
		&.folderDraggedOver {
			background: rgba(25, 37, 46, 0.06);
		}
		&.dense{
			padding-right: 3px;
		}

		.folderBody {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		.togglerSubFolders {
			width: 16px;
			height: 16px;
			display: flex;
			flex-shrink: 0;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}

		.folderIcon {
			width: 24px;
			height: 24px;
			flex-shrink: 0;

			&.hover:hover{
				background: rgba(25, 37, 46, 0.08);
				border-radius: 50%;
			}

			img {
				position: relative;
				top: -3px;
			}
		}

		.folderClear {
			border-radius: 20px;
			margin-left: auto;
			font-size: 14px;
			line-height: 14px;
			letter-spacing: 0.35px;
			color: #19252e;
			text-transform: capitalize;

			&:hover {
				background: rgba(25, 37, 46, 0.06);
			}
			&:active {
				background: rgba(25, 37, 46, 0.16);
			}
		}

		.folderName {
			margin-right: auto;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			height: 24px;
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 24px;
			color: #19252e;
		}

		.unseen-messages {
			display: flex;
			justify-content: flex-end;
			margin-right: 5px;
			padding-left: 12px;
			width: 40px;
			height: 24px;
			font-family: Roboto;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			color: #19252e;
			min-width: min-content;
			cursor: pointer;
		}
	}

	.brush-btn-container{
		position: relative;
		width: 34px;
		height: 24px;
		flex-shrink: 0;

		button{
			position: absolute;
			left: 0;
			top: -5px;
		}
	}

	&.minimized{
		.folder {
			padding-right: 0;

			.folderBody{
				justify-content: center;
			}

			.togglerSubFolders{
				width: auto;
			}
		}
	}
}

.clear-btn-popper-paper{
	margin-left: 8px;
	border-radius: 50%;

	.brush-icon-button{
		border-radius: 50%;
		background: rgba(25, 37, 46, 0.08);
		transition: background-color 0.2s linear;

		&:hover{
			background: rgba(25, 37, 46, 0.16);
		}
	}
}
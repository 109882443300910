.shared-account-pill {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2px;
	border-radius: 14px;
	// padding half the radius
	padding-right: 7px;
	background: var(--basic-light-fill-16, rgba(25, 37, 46, 0.16));
	height: 24px;
	max-width: 250px;
	width: fit-content;
	box-sizing: content-box;

	&.errorPill {
		border: 1px solid var(--Status-Error-Background-100, #E2333A);
		background: var(--Status-Error-Background-10, #FFF0F0);
	}

	span img {
		border-radius: 50%;
		width: 24px;
		height: 24px;
	}

	p {
		margin: 0;
		margin-left: 4px;
		color: var(--basic-light-outline-100, #19252e);
		font-family: Roboto;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	button {
		border: none;
		background: transparent;
		width: 24px;
		height: 24px;
		display: flex;
		padding: 0;
		border-radius: 50%;

		&:focus-visible {
			outline: none;
		}
	}

	&:has(> button) {
		padding-right: 4px;
	}
}
.signatureWrapper {
	height: auto;
	margin-top: 24px;
	max-width: 750px;
	padding-bottom: 24px;
    border-bottom: 1px solid rgba(25, 37, 46, 0.16);
	&:first-child {
		margin-top: 56px;
	}
}
.signatureMainLine {
	width: 100%;
	display: inline-block;

	.settingsH3 {
		width: 510px;
		word-break: break-all;
		display: inline-block;
	}
	.si-pencil, .si-trash {
		cursor: pointer;
		margin-left: 22px;
	}
}
.foldersSubSettings {
	.folderButtons {
		margin: 26px 0 26px 0;
		display: flex;
		flex-direction: row;

		.folderCreate, .folderSet  {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			margin-right: 16px;
			color: #19252E;
			border: 1px solid rgba(25, 37, 46, 0.4);
			border-radius: 4px;
		}
	}
}
.signatureBody {
	height: auto;
	max-width: 560px;
	margin-left: 40px;
	margin-top: 20px;
}
.defaultSignature {
	float: right;
	margin-top: 4px;
	font-family: 'Roboto' !important;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.35px;
	color: #A3A8AB;
	text-align: right;
}
.signatureFrame {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	height: auto;
	width: fit-content;
	min-width: 428px;
	
}
.signatureFrame > p {
	margin-bottom: 0px !important;
	
}

.nameRow {
	display: flex;
	flex-direction: row;

	.signatureMainButtons {
		display: flex;
		margin-left: auto;
	}
	.settingsSubtext {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: rgba(22, 31, 41, 0.6);

		margin-top: 1px;
		margin-right: 12px;
    	align-self: flex-start;
	}

	.settingsH3 {
		margin-bottom: 0;
		align-self: flex-start;
	}

	.radioRoot {
		margin-top: 1px;
    	width: 20px;
    	height: 20px;
    	margin-right: 13px;
		padding: 0 !important;
		align-self: baseline;

		:first-child {
			display: flex !important;
			align-items: center !important;
			justify-content: center !important;
		}
	}

	.MuiSvgIcon-root:first-child {
		position: unset !important;
	}
}


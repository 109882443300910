.single-compose {
	.detailLeftBlock {
		width: -webkit-fill-available;
		width: -moz-available;
	}
}
.MuiInput-underline {
	&:after {
		border-bottom: black 1px solid;
	}
}
.MuiAutocomplete-inputRoot[class*="MuiInput-root"] {
	.MuiAutocomplete-input {
		padding-bottom: 0px !important;
	}
}
.auto .autoInputRootClass:before,
.auto .autoInputRootClass:after {
	border-bottom: 1px solid rgba(25, 37, 46, 0.4) !important;
}

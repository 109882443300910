.soloButton {
	width: 24px;
	height: 24px;
	position: relative;
	svg {
		position: relative;
		opacity: 0;
	}
}
.soloButtonCustom {
	width: 24px;
	height: 24px;
	position: relative;
	cursor: pointer !important;
	height: 100%;
	display: flex;
	align-items: center;
	svg {
		position: relative;
	}
}
.divider {
	position: relative;
	width: 1px;
	height: 32px;
	background: rgba(0, 0, 0, 0.08);
}
#fonts {
	width: 120px;
	padding-left: 8px;
	display: flex;
	align-items: center;
	position: relative;
	.fontDrop {
		width: 24px !important;
		height: 24px !important;
		margin-left: 8px !important;
	}
}
#fontTitle {
	position: relative;
	width: 70px;
	height: 24px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	white-space: nowrap;
}
#fontSize {
	width: 58px;
	padding-left: 8px;
	display: flex;
	align-items: center;
	position: relative;
	.fontSizeDrop {
		width: 24px !important;
		height: 24px !important;
		margin-left: 8px !important;
	}
}
#fontSizeTitle {
	position: relative;
	width: 18px;
	height: 24px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	white-space: nowrap;
}
#fontColor {
	width: 48px;
	display: flex;
	align-items: center;
	position: relative;
}
#fontColorTitle {
	width: 24px;
	height: 24px;
	position: relative;
}
.fontDrop {
	button {
		width: 24px;
		height: 24px;
		border: none;
	}
	.dropdown-menu {
		width: 199px !important;
		height: 336px !important;
		position: absolute;
		left: 88px !important;
		top: 5px !important;
		border-radius: 4 !important;
		box-shadow: 0 0 8px #00000016, 8px 0 16px -2px #00000016, 8px 0 6px -4px #00000024 !important;
		.dropdown-item {
			padding-left: 12px !important;
		}
	}
	.dropdown-item {
		width: 100% !important;
		height: 40px !important;
		padding-left: 12px !important;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		&:hover {
			color: black !important;
			padding-left: 12px !important;
		}
	}
	.btn-secondary {
		box-shadow: none !important;
		background-color: white !important;
		border: none !important;
	}
}
.fontSizeDrop {
	button {
		width: 24px;
		height: 24px;
		border: none;
	}
	.dropdown-menu {
		width: 101px !important;
		min-width: 101px !important;
		height: 216px !important;
		position: absolute;
		left: 42px !important;
		top: 5px !important;
		border-radius: 4px !important;
		box-shadow: 0 0 8px #00000016, 8px 0 16px -2px #00000016, 8px 0 6px -4px #00000024 !important;
		.dropdown-item {
			padding-left: 12px !important;
		}
	}
	.dropdown-item {
		width: 100% !important;
		height: 40px !important;
		padding-left: 12px !important;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		&:hover {
			color: black !important;
			padding-left: 12px !important;
		}
	}
	.btn-secondary {
		box-shadow: none !important;
		background-color: white !important;
		border: none !important;
	}
}
.dropdown-menu {
	.dropdown-item {
		&:hover {
			background: rgb(235, 235, 235) !important;
		}
	}
}
.fontSizeDrop .btn-secondary.focus,
.btn-secondary:focus {
	box-shadow: none !important;
	background-color: white !important;
	border: none !important;
}
.fontDrop .btn-secondary.focus,
.btn-secondary:focus {
	box-shadow: none !important;
	background-color: white !important;
	border: none !important;
}
#colorWrapper {
	width: 400px;
	display: flex;
	height: 227px;
	box-shadow: 0 0 8px #00000016, 8px 0 16px -2px #00000016, 8px 0 6px -4px #00000024 !important;
	border-radius: 2px;
	position: absolute;
	top: 32px;
	left: -376px;
}
.composerToolbarWrapper {
	height: 40px;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 100;
	border-bottom: rgba(0, 0, 0, 0.08) 1px solid !important;
	width: 500px;
	display: flex;
	align-items: center;
	background: #ffffff;
}
.ql-toolbar {
	&.ql-snow {
		border-left: none !important;
		border-right: none !important;
		border-top: none !important;
		padding: 0 !important;
	}
}
.ql-bold {
	width: 24px !important;
	background: url('./svgs/Bold.svg?inline') !important;
}
.ql-italic {
	width: 24px !important;
	background: url('./svgs/Italic.svg?inline') !important;
}
.ql-underline {
	width: 24px !important;
	background: url('./svgs/Underline.svg?inline') !important;
}
.ql-strike {
	width: 24px !important;
	background: url('./svgs/Strike.svg?inline') !important;
}
.ql-clean {
	width: 24px !important;
	background: url('./svgs/Clean.svg?inline') !important;
}
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
	padding: 0px !important;
}
.btn-secondary {
	width: 24px !important;
}

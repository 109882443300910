#insertImgPopUp {
	width: 403px;
	height: 192px;
	position: absolute;
	top: 32px;
	left: -300px;
	background: #ffffff;
	box-shadow: 0 0 8px #00000016, 8px 0 16px -2px #00000016, 8px 0 6px -4px #00000024 !important;
}
#insertImgPopUpTitle {
	position: relative;
	top: 8px;
	left: 12px;
	height: 24px;
	line-height: 24px;
	display: inline-block;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
}
#insertImgPopUpLoad {
	height: 24px;
	line-height: 24px;
	color: #db7e00;
	position: relative;
	left: 12px;
	margin-top: 16px;
	.upload-button {
		label {
			display: contents;
		}
		.upload-button-file {
			display: block;
			z-index: 0;
			width: auto;
			height: auto;
		}
	}
}
#insertImgPopUpLink {
	display: inline-flex;
	position: relative;
	left: 12px;
	margin-top: 16px;
	width: 379px;
	height: 24px;
	line-height: 27px;
	border-bottom: rgba(0, 0, 0, 0.16) 1px solid;
	input {
		width: 315px;
		border: none;
	}
	div {
		height: 20px;
		cursor: pointer;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.6);
	}
}
#uploadedImgWrap {
	height: 24px;
	margin-left: 12px;
	margin-top: 16px;
	display: flex;
}
#uploadedImgName {
	width: 320px;
	height: 24px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}
#cancelUploadImg {
	height: 20px;
	display: inline-block;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.6);
	margin-left: 12px;
	margin-top: 2px;
}
#insertButton {
	position: relative;
	height: 32px;
	left: 12px;
	width: auto !important;
	padding-left: 12px !important;
	padding-right: 12px !important;
	margin-top: 28px;
	cursor: pointer;
	background: #f58d00;
	border-radius: 4px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
}
#cancelButton {
	height: 20px;
	display: inline-block;
	margin-left: 24px;
	margin-top: 34px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
}

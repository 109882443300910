#mailView {
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column;
}

#mailViewWrapper {
	overflow: auto;
	margin-right: 4px;
}

#mailViewHeaderWrapper {
	width: 100%;
	height: auto;
	position: relative;
	top: 0px;
}
#mailViewSubject {
	position: relative;
	height: auto;
	margin-left: 24px;
	margin-right: 52px;
	margin-top: 16px;
	margin-bottom: 16px;
	width: calc(100% - 62px);
	font-family: Roboto !important;
	font-style: normal;
	font-weight: 500;
	font-size: 19px;
	line-height: 24px;
	color: rgba(22, 33, 41, 0.92);
}
#mailViewDivider {
	position: relative;
	margin-right: 0px;
	background: rgba(0, 0, 0, 0.16);
	height: 1px;
	width: 100%;
}

#mailViewSecurityPanel {
	display: flex;
	flex-direction: row;
	margin: 0 24px;
	margin-top: 12px;
	font-family: PT Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.6);
	.securityPillChips {
		position: relative;
	}
	.icon {
		padding-left: 20px;
		background-repeat: no-repeat;
		background-position: -5px -1px;
		margin-left: 10px;
		cursor: pointer;
		&.encrypt {
			background-image: url('./../../assets/encrypt.svg?inline');
		}
		&.decrypted {
			background-image: url('./../../assets/decrypted.svg?inline');
			background-position: 0px;
		}
		&.decryptedFail {
			background-image: url('./../../assets/decryptedFail.svg?inline');
			background-position: 0px;
		}
		&.verifyProcess {
			background-image: url('./../../assets/sign.svg?inline');
		}
		&.verified {
			background-image: url('./../../assets/verified.svg?inline');
			background-position: 0px;
		}
		&.verifiedFail {
			background-image: url('./../../assets/verifiedFail.svg?inline');
			background-position: 0px;
		}
	}
}
#lineForMinimized {
	position: absolute;
	bottom: 0px;
	right: 16px;
}
#containerForMinimized {
	width: 315px;
	height: 40px;
	position: absolute;
	bottom: 0px;
	right: 16px;
}
.shareNewWindow {
	width: 24px;
	height: 24px;
	position: absolute;
	top: 12px;
	right: 60px;
}
#mailViewInfoWrapper {
	width: 100%;
	height: auto;
	min-height: 28px;
	position: relative;
	padding-top: 8px;
	margin-left: 0px;
}
.mailInfoBrief {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
}
.mailViewOptionHeader {
	position: relative;
	margin-left: 24px;
	height: 24px;
	width: 43px;
	min-width: 43px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	font-family: Roboto !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px;
	line-height: 24px;
	color: #596269;
}
#mailViewFromImg {
	position: relative;
	height: 24px;
	width: 24px;
	position: relative;
	margin-top: 4px;
	margin-left: 8px;
	img {
		border-radius: 12px;
		position: absolute;
	}
}
#mailViewFromFriendlyName {
	height: 20px;
	display: inline-block;
	margin-left: 4px;
	margin-top: 6px;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 20px !important;
	color: rgba(0, 0, 0, 0.8);
}
#mailViewFromMail {
	position: relative;
	display: inline-block;
	height: 20px;
	margin-left: 9px;
	margin-top: 6px;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 20px !important;
	color: rgba(0, 0, 0, 0.6);
}
#mailViewExpandInfo {
	position: relative;
	width: 24px;
	height: 24px;
	margin-right: 24px;
	cursor: pointer !important;
}
#mailViewBriefDate {
	position: relative;
	display: 'inline-block';
	height: 24px;
	margin-right: 8px;
	font-family: Roboto !important;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: #000000;
	text-align: right;
}
#mailContent {
	margin-bottom: 0px;
	height: auto;
	width: fit-content;
	min-width: calc(100% - 48px);
	position: relative;
	margin-left: 24px;
	margin-right: 24px;
	padding-top: 8px;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding-bottom: 16px;
}
#replyFieldInputWrapper {
	input {
		border: none;
		font-family: Roboto !important;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		color: #19252e;
		width: 100%;
		&:placeholder {
			font-family: Roboto !important;
			font-style: normal !important;
			font-weight: normal !important;
			font-size: 16px;
			line-height: 24px;
			color: #596269;
		}
		&:focus {
			border: none;
			outline: none;
		}
	}
}
#noSelectedMail {
	height: 100%;
	width: 100%;
	display: flex;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
	justify-content: center;
	align-items: center;
}

#mailDecryptFiles {
	font-family: PT Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
	text-align: center;
	width: 294px;
	margin: 80px auto;
	.buttons {
		display: flex;
		flex-direction: row;
		margin: 24px 44px;
		div {
			border: 1px solid rgba(22, 33, 41, 0.64);
			font-family: Roboto;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 24px;
			color: rgba(22, 33, 41, 0.64);
			border-radius: 4px;
			margin-right: 12px;
			padding: 2px 12px;
			cursor: pointer;
		}
	}
}
#mailDecryptFiles .buttons a,
#mailDecryptFiles .buttons a:hover {
	text-decoration: none;
	color: rgba(22, 33, 41, 0.64);
}
#content-wrapper {
	height: min-content;
	position: relative;
	width: 100%;
	overflow-x: hidden;
	max-width: 100%;

	* {
		box-sizing: unset;
	}
	.f-composer-file-link-wrapper {
		margin-right: 20px;
	}
}
.expandedOption {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	position: relative;
	&:first-of-type {
		margin-top: 8px;
	}
}
.mailViewOptionBody {
	height: auto;
	max-height: 75px;
	overflow: auto;
	margin-right: 32px;
	display: flex;
	flex-wrap: wrap;
}
#mailContentDivider {
	position: relative;
	display: inline-table;
	width: 100%;
	height: 1px;
	margin-top: 16px;
	background: rgba(0, 0, 0, 0.16);
}
#mailContentFiles {
	position: relative;
	width: 100%;
	height: 168px;
	display: inline-table;
}
#downloadRow {
	position: relative;
	margin-bottom: 17px;
	display: flex;
	flex-direction: row;
	align-items: center;
	.dropdown-menu {
		.dropdown-item {
			padding-left: 12px !important;
			&:hover {
				padding-left: 12px !important;
			}
		}
	}
}
#downloadAllIcon {
	width: 24px;
	height: 24px;
	position: relative;
	bottom: 0px;
	left: 0px;
}
#downloadAllTitle {
	cursor: pointer;
	position: relative;
	height: 24px;
	display: inline-block;
	bottom: 0px;
	font-family: Roboto !important;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.35px;
	color: #19252e;
}
#toggleFilesWrapper {
	float: right;
	display: flex;
	margin-right: 0px;
	margin-left: auto;
}
#toggleFilesTitle {
	font-family: Roboto !important;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.35px;
	color: #19252e;
	text-align: right;
}
#toggleFilesView {
	width: 24px;
	height: 24px;
	position: relative;
}
#filesRow {
	position: relative;
	top: 0px;
	left: 0px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}
.downloadDrop {
	position: relative;
	top: 0px !important;
	width: 24px;
	height: 24px;
	margin-top: -2px;
	.dropdown-item {
		height: 40px !important;
	}
	.dropdown-menu {
		left: 4px !important;
		width: 222px;
		height: 96px;
		top: 6px !important;
		border-radius: 4px !important;
		border: none !important;
		box-shadow: 0 0 8px #00000016, 8px 0 16px -2px #00000016, 8px 0 6px -4px #00000024;
		.dropdown-item {
			&:hover {
				background: rgb(235, 235, 235) !important;
				padding-left: 24px !important;
			}
		}
	}
	.btn-secondary {
		box-shadow: none !important;
		background-color: white !important;
		border: none !important;
		display: flex;
		height: 100%;
		svg {
			position: relative;
		}
	}
}
.downloadDrop .btn-secondary.focus,
.btn-secondary:focus {
	box-shadow: none !important;
	background-color: white !important;
	border: none !important;
}
.btn-secondary:focus {
	box-shadow: none !important;
	background-color: white !important;
	border: none !important;
}
.mailSensitivityInfo {
	width: 24px;
	height: 24px;
	cursor: pointer !important;
	margin-right: 8px;
	position: relative;
	margin-left: auto;
}
.mailViewLoader {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	& > div {
		width: 32px !important;
		height: 32px !important;
		color: #ff962e !important;
		& > svg {
			& > circle {
				stroke: #ff962e;
				stroke-width: 2px;
			}
		}
	}
}

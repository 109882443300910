.desktop-date-picker {
	.MuiInput-root:not(.Mui-disabled) {
		input {
			cursor: pointer;
		}
	}
}

.date-picker-paper {
	.MuiCalendarOrClockPicker-root {
		& > div {
			width: 296px;
		}

		.MuiCalendarPicker-root {
			padding: 16px 8px;
			width: 296px;
			height: 328px;
			margin: 0;
			overflow: hidden;

			.MuiPickersCalendarHeader-root {
				padding: 0;
				margin: 0 0 12px 0;

				.MuiPickersCalendarHeader-labelContainer {
					padding: 4px 16px;
					border-radius: 4px;
					transition: background 0.3s;

					&:hover {
						background: rgba(25, 37, 46, 0.08);
					}
				}

				.MuiPickersArrowSwitcher-root {
					.MuiButtonBase-root {
						padding: 0;
						margin-left: 12px;
						width: 32px;
						height: 32px;
						transition: background 0.3s;

						&:hover {
							background: rgba(25, 37, 46, 0.08);
						}
					}
					.MuiPickersArrowSwitcher-spacer {
						display: none;
					}
				}
			}

			.PrivatePickersSlideTransition-root {
				min-height: 216px;
			}

			.MuiYearPicker-root {
				max-height: 252px;
				padding: 0 2px;
			}

			.MuiDayPicker-header {
				margin-bottom: 12px;

				.MuiDayPicker-weekDayLabel {
					height: 24px;
				}
			}

			.MuiDayPicker-monthContainer {
				padding: 0 2px;

				.MuiDayPicker-weekContainer {
					margin: 0;
					justify-content: space-between;
				}
			}

			.MuiYearPicker-root {
				scrollbar-color: rgba(0, 0, 0, 0.16);
				scrollbar-width: thin;
				&::-webkit-scrollbar {
					display: block;
					width: 4px;
				}
				&::-webkit-scrollbar-track {
					box-shadow: unset;
					background-color: white;
				}
				&::-webkit-scrollbar-thumb {
					border-radius: 4px;
					background-color: rgba(0, 0, 0, 0.16);
				}
			}
		}
	}

	.MuiPickersDay-root {
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 0.35px;
		border: unset;
		transition: background 0.3s;
		margin: 0;

		&:hover {
			background: rgba(25, 37, 46, 0.08);
		}

		&.MuiPickersDay-today {
			background: rgba(255, 150, 46, 0.56);
			font-weight: 500;
		}

		&.Mui-selected {
			background: rgba(255, 150, 46, 0.4);
		}

		&:not(.Mui-disabled) {
			color: #19252e;
		}
	}

	.PrivatePickersYear-root {
		.PrivatePickersYear-yearButton {
			padding: 0;
			margin: 0;
			width: 36px;
			height: 36px;
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;
			letter-spacing: 0.35px;

			&.Mui-selected {
				background: rgba(255, 150, 46, 0.56);
				font-weight: 500;
				color: #19252e;
			}

			&:not(.Mui-disabled) {
				color: #19252e;
			}

			&.Mui-disabled {
				cursor: default;

				&:hover {
					background-color: transparent;
				}
			}
		}
	}
}

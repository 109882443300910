.avatar-container {
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s linear;

  &:hover,
  &.active {
    background-color: rgba(255, 150, 46, 0.4);
  }

  .avatar {
    font-size: 16px;
  }
}

.bottom-menu-profilePaper {
  min-width: 200px;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: initial;

    a {
      color: #19252e;
      text-decoration: none;
    }
    a:hover {
      color: unset;
      text-decoration: none;
    }
  }

  .icon:hover {
    background-color: transparent;
  }
}

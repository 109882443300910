.contactsSettings {
	height: calc(100% - 56px);
	overflow-y: scroll;
	width: 100%;
	overflow-x: hidden;
	scroll-behavior: smooth;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

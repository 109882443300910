.mail-labels-menu {
  .MuiPaper-root {
    padding: 8px 0;
  }

  .MuiMenuItem-root {
    padding: 8px 12px 8px 8px;
  }

  .mail-mark__label {
    max-width: 140px;
  }

  .psnCheckboxRoot {    
    label {
      margin: 0
    }
  }
}
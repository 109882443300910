.messageComposerWrapper {
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;

	#editorWrapper {
		margin-top: 16px;
		width: 672px;
		margin-left: 24px;
		height: auto;
		background: #ffffff;
		position: relative;
		overflow-y: scroll;
		max-height: 520px;
	}

	blockquote {
		border-left: solid 2px #000000 !important;
		margin: 4px 2px !important;
		padding-left: 6px !important;

		div {
			direction: ltr;
			white-space: normal;
			color: black;
		}
	}

	html {
		&::-webkit-scrollbar {
			display: none;
			-ms-overflow-style: none;
			scrollbar-width: none;
		}
	}
}

.froala-container {
	width: 740px;
	overflow-x: visible;
	overflow-y: scroll;
	position: relative;
	-ms-overflow-style: none;
	scrollbar-width: none;

	svg {
		width: 24px !important;
		height: 24px !important;
		position: relative;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	
	.f-composer-file-link-wrapper {
		margin-right: 20px;
		height: 65px;

		&:hover {
			box-shadow: 0px 0px 8px rgba(25, 37, 46, 0.08), 0px 8px 8px -4px rgba(25, 37, 46, 0.08);
		}
	}

	.widget-file__delete-button {
		position: absolute;
		top: 2px;
		right: 2px;
		border: none;
		border-radius: 50%;
		width: 24px;
		height: 24px;
		text-decoration: none;
		background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IjI0LXgvc21hbGwiPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNNyA4LjRMOC40IDdMMTIgMTAuNkwxNS42IDdMMTcgOC40TDEzLjQgMTJMMTcgMTUuNkwxNS42IDE3TDEyIDEzLjRMOC40IDE3TDcgMTUuNkwxMC42IDEyTDcgOC40WiIgZmlsbD0iIzE5MjUyRSIvPgo8L2c+Cjwvc3ZnPgo=") center no-repeat;
	}

	.widget-file__delete-button:hover {
		background-color: white;
	}
}

.froala-container,
.single-composer-field {
	.fr-box.fr-basic .fr-element {
		font-family: 'XO Tahion';
		color: black;
	}

	.fr-box {
		flex: 1 1 auto !important;
		display: flex !important;
		flex-flow: column !important;
	}

	.fr-wrapper {
		flex: 1 1 auto !important;
		border: none !important;

		.fr-view {
			.fr-element-files {
				.f-composer-file-link-wrapper>a>img {
					margin: 0 !important;
				}
			}
		}

		a {
			color: #009ff9 !important;
		}
	}

	.fr-element {
		height: 100% !important;
		padding: 24px !important;
		border: none !important;
	}

	.fr-element>div[data-anchor='signature']>p {
		margin-bottom: 0px !important;
	}

	.second-toolbar {
		display: none !important;
	}

	.fr-sticky-dummy {
		display: none !important;
	}

	.fr-toolbar {
		position: sticky !important;
		height: 48px !important;
		border-left: none !important;
		border-right: none !important;
		border-top: none !important;
		top: 0 !important;
		border-radius: 0 !important;

		.fr-command {
			&.fr-btn {
				margin: 4px 4px;

				&:hover {
					background: rgba(25, 37, 46, 0.08) !important;
				}

				&[aria-pressed='true'] {
					background: rgba(255, 150, 46, 0.56) !important;
				}

				&[data-cmd='bold'],
				&[data-cmd='italic'],
				&[data-cmd='underline'],
				&[data-cmd='strikeThrough'],
				&[data-cmd='formatOL'],
				&[data-cmd='formatUL'],
				&[data-cmd='quote'],
				&[data-cmd='undo'],
				&[data-cmd='redo'],
				&[data-cmd='textColor'],
				&[data-cmd='backgroundColor'],
				&[data-cmd='insertLink'],
				&[data-cmd='insertImage'],
				&[data-cmd='clearFormat'] {
					height: 24px !important;
					// width:32px !important;
					// margin: 0 !important;
					border-radius: 50% !important;
				}
			}
		}
	}

	.fr-btn-grp {
		margin-left: 18px !important;
		height: 100% !important;
		display: flex !important;
		flex-wrap: wrap !important;
		align-items: center !important;
	}

	.fr-btn {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.fr-newline {
		display: none !important;
	}

	.fr-svg {
		path {
			fill: #19252e;
		}
	}

	.fr-dropdown-list {
		a {
			height: 40px !important;
			padding-left: 12px !important;
			line-height: 40px !important;
		}
	}

	li {
		list-style-type: inherit;
	}

	.fontColorPopUp {
		margin-left: -150px;
	}

	.fr-color-hex-layer {
		display: none !important;
	}

	.fr-color-set {
		padding-left: 11px !important;
		padding-right: 11px !important;
		padding-bottom: 15px !important;
		padding-top: 5px !important;
		order: 2;
	}

	.fr-select-color {
		margin: 1px !important;
		width: 20px !important;
		height: 20px !important;
	}

	.fr-dropdown-wrapper {
		max-height: 350px !important;
		width: 100% !important;
	}

	div[id*='dropdown-menu-fontFamily'] {
		width: 200px !important;
		margin-top: 4px;
		margin-left: -4px;
	}

	.fr-dropdown-menu {
		box-shadow: 0 0 8px #00000016, 8px 0 16px -2px #00000016, 8px 0 6px -4px #00000024 !important;
		border-radius: 0 !important;
	}

	div[id*='dropdown-menu-fontSize'] {
		width: 100px !important;
		margin-top: 4px;
		margin-left: -4px;
	}

	button[data-cmd='vDivider'] {
		cursor: inherit !important;
		width: 1px !important;
		height: 32px !important;
		background: rgba(0, 0, 0, 0.08) !important;
	}

	.fr-selected-color {
		display: none !important;
	}

	.fr-view {
		.fr-img-caption {
			display: block;
			margin: 5px auto;

			.fr-img-wrap {
				img {
					margin: 5px auto;
				}
			}
		}

		.fr-img-space-wrap {
			img {
				display: block;
				margin: 5px auto;
			}
		}

		blockquote,
		blockquote blockquote {
			color: #161f29;
		}
	}

	.replyExcludeStyles {
		td {
			border: none;
		}

		img {
			max-width: none;
		}
	}

	div[data-anchor='signature'] {
		p {
			margin-bottom: 0;
		}
	}

	ul[role='listbox'] {
		z-index: 10020 !important;
	}

	button[data-cmd='redo'],
	button[data-cmd='insertImage'],
	button[data-cmd='insertLink'],
	button[data-cmd='undo'],
	button[data-cmd='backgroundColor'],
	button[data-cmd='bold'],
	button[data-cmd='italic'],
	button[data-cmd='underline'],
	button[data-cmd='strikeThrough'],
	button[data-cmd='clearFormat'],
	button[data-cmd='formatOL'],
	button[data-cmd='formatUL'],
	button[data-cmd='textColor'],
	button[data-cmd='quote'] {
		background: transparent !important;
	}

	.fontColorPopUp,
	.backColorPopUp {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-orient: vertical;
		-moz-box-orient: vertical;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-moz-box-align: start;
		-ms-flex-align: start;
		-webkit-align-items: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
		border-radius: 2px !important;

		.popUpHeader,
		.popUpHeader {
			order: 0;
			margin-left: 12px !important;
			margin-top: 8px !important;
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			line-height: 24px;
			color: #000000;
		}
	}

	button[data-cmd='fontFamily'],
	button[data-cmd='fontSize'] {
		background: transparent !important;
	}

	button[data-cmd='formatOLOptions'],
	button[data-cmd='formatULOptions'] {
		display: none;
	}

	button[data-cmd='vDivider'] .fr-svg,
	button[data-cmd='vDivider'] .fr-sr-only {
		display: none !important;
	}

	.fr-popup {

		&.linkInsertPopUp,
		&.imageInsertPopUp {
			margin-left: -376px;
		}
	}
}

.fr-cancel {
	padding: 4px 12px 4px 12px;
	height: 32px;
	background: #ffffff;
	border: 1px #bdbdbd solid;
	border-radius: 4px;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 24px;
	color: rgba(22, 33, 41, 0.64);
}

.fr-clear {
	display: block;
	position: absolute !important;
	right: 0;
	bottom: 0;
	transition: background 0.2s linear;
	z-index: 3;
	border-radius: 50%;
	cursor: pointer;

	&:hover {
		background-color: #edeeee;
	}
}

.fr-toolbar {
	.fr-command {
		&.fr-btn {
			svg.fr-svg {
				margin: 0;
			}

			&.fr-dropdown {
				&.fr-selection {
					span {
						font-family: Roboto;
						font-style: normal;
						font-weight: 500 !important;
						font-size: 14px;
						letter-spacing: 0.35px;
						color: #19252e;
					}
				}
			}
		}
	}
}

.fr-toolbar .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path {
	//remove blue on active
	fill: #000000;
}

.fr-popup {
	&.fr-desktop {
		&.fr-active {
			z-index: 20000 !important;

			.fr-buttons {
				padding: 0;

				&>button[data-cmd='linkStyle'] {
					display: none !important;
				}

				&>button[data-cmd='linkEdit'] {
					&>svg {
						&>path {
							transform: translate(4px, 4px);
							opacity: 0.6;
						}
					}
				}

				&>button[data-cmd='linkOpen'] {
					&>svg {
						&>path {
							opacity: 0.6;
						}
					}
				}

				&>button[data-cmd='linkRemove'] {
					&>svg {
						&>path {
							opacity: 0.6;
						}
					}
				}
			}
		}
	}
}

.fr-popup {

	&.linkInsertPopUp,
	&.imageInsertPopUp {
		width: 400px;
		border-radius: 0px;
		margin-left: 0;

		.fr-image-upload-layer,
		.fr-image-by-url-layer,
		.fr-image-progress-bar-layer {
			width: calc(100% - 24px);
			margin-left: 12px;
			margin-right: 12px;
		}

		.fr-input-line {
			height: 48px;
			width: 100%;
			padding: 0;
			margin-bottom: 12px;
			padding-top: 24px;
			position: relative;

			input[type='text'] {
				width: calc(100% - 24px);
				padding: 0;
				height: 24px;
				border: none;
				border-bottom: 1px solid rgba(0, 0, 0, 0.16);

				&:hover,
				&:focus {
					border: none;
					border-bottom: 1px solid rgba(0, 0, 0, 0.16);
					padding: 0;
				}
			}
		}

		.fr-buttons.fr-tabs {
			background-color: #ffffff;
			display: flex;
			margin-top: 8px;
			padding-left: 12px;

			button {
				margin: 0;

				.fr-svg {
					margin: auto;
				}
			}
		}

		.fr-action-buttons {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: flex-end;

			button.fr-command.fr-submit {
				padding: 4px 12px 4px 12px;
				height: 32px;
				margin-left: 12px;
				order: 2;
				background-color: #f58d00;
				border-radius: 4px;
				font-family: Roboto;
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 24px;
				color: #ffffff;
			}
		}
	}

	&.linkInsertPopUp {
		padding: 16px 12px;

		.fr-buttons.fr-tabs,
		.fr-checkbox-line {
			display: none;
		}

		.fr-link-insert-layer {
			width: 100%;
			margin: 0;
		}

		.fr-input-line {

			label,
			input.fr-not-empty+label {
				padding: 0;
				margin: 0;
				top: 0;
				left: 0;
				font-weight: bold;
				font-size: 16px;
				line-height: 24px;
				color: #000000;
			}
		}
	}

	&.imageInsertPopUp {
		.fr-separator {
			display: none;
		}

		.fr-image-by-url-layer {
			.fr-input-line {
				label {
					display: none;
				}
			}
		}

		.fr-image-progress-bar-layer {
			margin-top: 12px;

			&>div {
				&.fr-loader {
					background: #fbdaad;

					span {
						background: #f58d00;
					}
				}
			}

			.fr-action-buttons {
				margin-top: 44px !important;
			}
		}

		.fr-image-upload-layer {
			border: 2px dashed rgba(22, 33, 41, 0.92);
		}

		button[data-cmd='imageBack'] {
			display: none;
		}

		button[data-cmd='imageByURL'] {
			margin-left: 16px;
		}

		.fr-action-buttons {
			width: calc(100% - 24px);
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
		}

		// button[data-cmd='imageUpload'],
		// button[data-cmd='imageByURL'] {
		// 	width: auto;
		// 	height: 24px;
		// }
		button[data-cmd='imageUpload'].fr-active,
		button[data-cmd='imageByURL'].fr-active {
			border-bottom: 2px solid rgba(223, 128, 0, 0.92) !important;
		}

		.fr-image-by-url-layer .fr-submit,
		.fr-dismiss {
			height: 32px;
			padding-left: 12px;
			margin-left: 12px;
			background: #f58d00;
			border-radius: 4px;
			padding-right: 12px;
			padding-top: 4px;
			padding-bottom: 4px;
			font-family: Roboto;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 24px;
			color: #ffffff;
		}
	}
}
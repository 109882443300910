.single-view-footer {
	padding-left: 24px;
	padding-right: 12px;
	border-top: 1px solid rgba(22, 31, 41, 0.24);

	.main-line {
		height: 48px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		& > img {
			width: 24px;
			height: 24px;
			border-radius: 50%;
		}
		& > input {
			border: none;
			font-family: Roboto !important;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			color: #19252e;
			width: inherit;
			margin-left: 12px;
			&::placeholder {
				font-size: 14px;
				line-height: 20px;
				color: rgba(22, 33, 41, 0.48);
			}
			&:focus {
				outline: none;
			}
		}
	}
	.single-button-line {
		width: 100%;
		height: 40px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
}

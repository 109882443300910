.messageComposerWrapper {
	.msgHeader {
		width: 100%;
		position: relative;
		height: 56px;
		padding-left: 24px;
		padding-right: 16px;
		display: flex;
		flex-shrink: 0;
		flex-direction: row;
		align-items: center;
		border-bottom: 1px solid rgba(25, 37, 46, 0.16);
	}
	#headerTxt {
		height: 24px;
		position: relative;
		font-family: Roboto !important;
		font-style: normal;
		font-weight: 500;
		font-size: 19px;
		line-height: 24px;
		color: rgba(22, 33, 41, 0.92);
	}
	#openInNewTab {
		width: 24px;
		cursor: pointer;
		height: 24px;
		margin-left: auto;
		margin-right: 20px;
	}
	#toggleMinimized {
		width: 24px;
		height: 24px;
		cursor: pointer;
		margin-right: 20px;
	}
	#closeMessage {
		width: 24px;
		height: 24px;
		cursor: pointer;
	}
}

.edit-label {
	&__paper {
		max-width: 400px;
		width: 100%;
	}

	&__header {
		padding: 16px 24px 24px 24px;
		font-family: Roboto;
	}

	&__title {
		font-size: 19px;
		line-height: 24px;
		padding: 4px 0;
		margin-bottom: 4px;
	}

	&__subheader {
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;
		letter-spacing: 0.35px;
	}

	&__content {
		padding: 0 24px;
		margin-bottom: 32px;
		position: relative;
	}

	&__form-control {
		.edit-label {
			&__input-root {
				margin-top: 28px;
				padding: 4px 4px 6px 4px;
			}

			&__input {
				padding: 0;
				height: 24px;
				font-size: 14px;
				line-height: 24px;
			}

			&__input-label {
				font-family: Roboto;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
				color: rgba(25, 37, 46, 0.72);
				transform: none;

				&.Mui-focused {
					color: rgba(25, 37, 46, 0.72);
				}
			}

			&__helper-text.Mui-error {
				margin-top: 0;
				padding-left: 12px;
				font-family: Roboto;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
				color: #d43a3e;
			}
		}
	}

	&__actions {
		padding: 0 24px 16px 24px;
		justify-content: flex-start;

		>:not(:first-of-type) {
			margin-left: 12px;
		}
	}

	&__save,
	&__cancel {
		font-family: Roboto;
		color: #19252e;
		border-radius: 4px;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0.35px;
		box-shadow: none;

		&.Mui-disabled,
		&:hover {
			box-shadow: none;
		}
	}

	&__save {
		padding: 4px 12px;
		background-color: rgba(255, 150, 46, 0.56);
	}

	&__cancel {
		padding: 3px 12px;
		border: 1px solid rgba(25, 37, 46, 0.4);
	}
}

.PsnAutocomplete {
	&_optionWrap {
		height: 40px;
		width: 100%;
		padding: 0 12px;
		display: flex;
		flex-direction: row;
		align-items: center;

		img {
			width: 24px;
			height: 24px;
			border-radius: 50%;
			margin-right: 8px;
		}

		p {
			margin: 0;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			width: 100%;
			color: #162129;
			font-family: Roboto;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 32px; /* 228.571% */
		}
	}
}

.psn-autocomplete {
	&-popper {
		margin-top: 1px !important;

		.MuiAutocomplete-noOptions {
			padding: 8px 0;
			display: none;
		}
	}

	&-listbox {
		max-height: 416px;
		&::-webkit-scrollbar {
			width: 14px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: transparent;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			border: 4px solid rgba(0, 0, 0, 0);
			background-clip: padding-box;
			border-radius: 9999px;
			// background-color: #AAAAAA;

			// border: 4px solid rgba(0, 0, 0, 0);
			// background-clip: padding-box;

			background-color: var(--black-fill-16, rgba(25, 37, 46, 0.16));
		}
	}

	label {
		color: var(--basic-light-fill-56, rgba(25, 37, 46, 0.56)) !important;
	}
}

.mailContentFiles {
	position: relative;
	width: 100%;
	height: 180px;
	padding-left: 24px;
	padding-right: 24px;
	display: inline-table;
}
.filesRow {
	position: relative;
	top: 0px;
	padding-left: 1px;
	left: 0px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	// &::-webkit-scrollbar {
	// 	display: none;
	// }
}
.downloadRow {
	position: relative;
	margin-bottom: 17px;
	display: flex;
	flex-direction: row;
	align-items: center;
	.dropdown-menu {
		.dropdown-item {
			padding-left: 12px !important;
			&:hover {
				padding-left: 12px !important;
			}
		}
	}
}
.downloadAllIcon {
	height: 24px;
	position: relative;
	width: 24px;
	height: 24px;
}
.downloadAllTitle {
	cursor: pointer;
	position: relative;
	height: 24px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}
.toggleFilesWrapper {
	float: right;
	display: flex;
	margin-right: 0px;
	margin-left: auto;
}
.toggleFilesTitle {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	text-align: right;
	color: rgba(0, 0, 0, 0.6);
}
.toggleFilesView {
	width: 24px;
	height: 24px;
	position: relative;
}
#root {
	.single-view {
		.single-body {
			.mailContentFiles {
				.downloadDrop {
					&.dropdown {
						position: relative;
						width: 24px;
						height: 24px;
						display: flex !important;
					}
				}
			}
		}
	}
}
.single-body {
	#content-wrapper {
		height: auto;
		position: relative;
		width: 100%;
		overflow-x: hidden;
		max-width: 100%;
	}
}
.downloadDrop {
	.dropdown-item {
		height: 40px !important;
	}
	.dropdown-menu {
		left: 4px !important;
		width: 222px;
		height: 96px;
		top: 6px !important;
		border-radius: 4px !important;
		border: none !important;
		box-shadow: 0 0 8px #00000016, 8px 0 16px -2px #00000016,
    8px 0 6px -4px #00000024;
		.dropdown-item {
			&:hover {
				background: rgb(235, 235, 235) !important;
				padding-left: 12px !important;
			}
		}
	}
	.btn-secondary {
		box-shadow: none !important;
		background-color: white !important;
		border: none !important;
		padding: 0 !important;
		width: 24px;
		height: 24px;
		svg {
			position: relative;
		}
	}
}
.downloadDrop .btn-secondary.focus,
.btn-secondary:focus {
	box-shadow: none !important;
	background-color: white !important;
	border: none !important;
}
#mailDecryptFiles {
	font-family: PT Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
	text-align: center;
	width: 294px;
	margin: 80px auto;
}

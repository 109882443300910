.webpush-front-toast {
  height: auto;
  width: 364px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(25, 37, 46, 0.16);
  border-radius: 8px;
  background: #f5f5f5;
  padding: 8px;
  & > div:first-of-type {
    height: auto;
    width: 100%;
    cursor: pointer !important;
    display: flex;
    flex-direction: row;
    & > svg {
      position: relative;
    }
    & > p {
      margin: 0;
      margin-left: 8px;
      max-width: 276px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      cursor: pointer !important;
    }
    & > span {
      width: 276px;
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      & > p{
        margin: 0;
        max-width: 100%;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        cursor: pointer !important;
        overflow: hidden;
        text-overflow: ellipsis;
        &:nth-of-type(1){
          max-height: 24px;
          white-space: nowrap;
        }
        &:nth-of-type(2){
          max-height: 72px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
    & > button {
      background: transparent;
      display: flex;
      width: 24px;
      border-radius: 8px;
      height: 24px;
      border: none;
      outline: none;
      cursor: pointer;
      margin-left: auto;
      padding: 0;
      & > svg {
        position: relative;
      }
      &:hover {
        background: #e6e6e6;
      }
      &:active{
        background: #DBDADA;
      }
    }
  }
}

.webpush-footer-calendar {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  & > div:first-of-type {
    display: flex;
    margin-top: 1px;
    height: 24px;
    flex-direction: row;
    align-items: center;
    & > svg {
      position: relative;
    }
    & > p {
      margin: 0;
      margin-left: 4px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      height: 20px;
      line-height: 20px;
      color: #000000;
      cursor: pointer !important;
    }
  }
  & > div:nth-of-type(2) {
    padding-right: 28px;
    padding-left: 28px;
    display: flex;
    flex-direction: row;
    & > p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      align-self: end;
      font-size: 14px;
      line-height: 20px;
      height: auto;
      min-height: 20px;

      color: #000000;
      margin: 0;
      cursor: pointer !important;
    }
  }
}

.webpush-footer-mail {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-left: 36px;
  & > p {
    margin: 0;
    cursor: pointer !important;
    margin-top: 4px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-width: 304px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  & > div {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    & > button:not(.toggle-webpush-thread) {
      height: 26px;
      width: fit-content;
      padding: 2px 8px;
      border: 1px solid rgba(25, 37, 46, 0.16);
      border-radius: 16px;
      outline: none;
      cursor: pointer;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.35px;
      color: #2b363f;
      // box-sizing: content-box;
      &:hover {
        background: #e6e6e6;
      }
      &:active{
        background: #DBDADA;
      }
      &:nth-of-type(2) {
        margin-left: 8px;
      }
    }
  }
}

.webpush-footer-task {
  margin-top: 10px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  & > div:first-of-type {
    display: flex;
    margin-top: 1px;
    height: 24px;
    flex-direction: row;
    align-items: center;
    & > svg {
      position: relative;
    }
    & > p {
      margin: 0;
      cursor: pointer !important;
      margin-left: 4px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      height: 20px;
      line-height: 20px;
      color: #000000;
    }
  }
  & > div:nth-of-type(2) {
    padding-right: 28px;
    padding-left: 28px;
    display: flex;
    flex-direction: row;
    & > p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      align-self: end;
      font-size: 14px;
      line-height: 20px;
      height: 20px;
      color: #000000;
      margin: 0;
      cursor: pointer !important;

    }
  }
}

.webpush_action_button {
  height: 27px;
  width: fit-content;
  padding: 4px 8px;
  font-family: "Roboto";
  font-style: normal;
  display: flex;
    font-weight: 500;
    flex-direction: row;
    align-items: center;
  font-size: 14px;
  line-height: 17px;
  color: #2b363f;
  background: #F5F5F5;
  border: 1px solid rgba(25, 37, 46, 0.16);
  border-radius: 16px;
  outline: none;
  cursor: pointer;
  &:hover {
    background: #e6e6e6;
  }
  &:active{
    background: #DBDADA;
  }
  & > svg {
    position: relative;
    margin-left: 8px;
  }
}

.webpush_action_menu {
  margin-top: 29px;
  position: absolute;
  width: 148px;
  height: auto;
  background: #f5f5f5;
  z-index: 10000;
  padding: 4px 0;
  border: 1px solid #d2d4d5;
  box-shadow: 0px 0px 8px rgba(25, 37, 46, 0.08),
    0px 8px 8px -4px rgba(25, 37, 46, 0.08);
  border-radius: 4px;
  & > button {
    width: 100%;
    height: 24px;
    text-align: start;
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    padding-left: 8px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
    // letter-spacing: 0.35px;
    color: #19252e;
    &:hover {
      background: #e6e6e6;
    }
    &:disabled {
      opacity: 0.4;
    }
  }
}

.webpush_actions {
  display: flex;
  margin-left: auto;
  width: fit-content;
  flex-direction: column;
}

.webpush-front-toast-wrap {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  //remove
  // margin-left: 100px;
}

.threadLayerSecond {
  width: 348px;
  margin-left: 8px;
  background: #f5f5f5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid rgba(25, 37, 46, 0.16);
  border-top: none;
  box-shadow: 0px 0px 8px rgba(25, 37, 46, 0.08),
    0px 8px 8px -4px rgba(25, 37, 46, 0.08);
  height: 12px;
}

.threadLayerThird {
  width: 332px;
  margin-left: 16px;
  background: #f5f5f5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid rgba(25, 37, 46, 0.16);
  border-top: none;
  box-shadow: 0px 0px 8px rgba(25, 37, 46, 0.08),
    0px 8px 8px -4px rgba(25, 37, 46, 0.08);
  height: 12px;
}

.close-webpush-thread {
  height: 26px;
  width: fit-content;
  padding: 4px 8px;
  margin-bottom: 8px;
  margin-left: auto;
  box-shadow: 0px 8px 8px -4px rgba(25, 37, 46, 0.08);
  filter: drop-shadow(0px 0px 8px rgba(25, 37, 46, 0.08));
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.35px;
  color: #2b363f;
  background: #f5f5f5;
  border: 1px solid #d2d4d5;
  border-radius: 16px;
  &:hover {
    background: #e6e6e6;
  }
  &:active{
    background: #DBDADA;
  }
}

.toggle-webpush-thread{
  cursor: pointer;
  margin-left: auto;
  border-radius: 8px;
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  display: flex;
  width: 24px;
  height: 24px;
  &:hover {
    background: #e6e6e6;
  }
  &:active{
    background: #DBDADA;
  }
}
.mailItem {
	width: 100%;
	height: 64px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 8px 8px 16px;
	font-family: Roboto;
	border-bottom: 1px solid #e8e8e8;

	.mailItemLeftContainer {
		display: flex;
		flex-direction: row;
		margin-right: 8px;
		flex-shrink: 0;

		.mailItemPhoto {
			img {
				width: 36px;
				height: 36px;
				border-radius: 50%;
			}
		}
	}

	.mailItemRightContainer {
		display: flex;
		flex-grow: 1;
		width: 0;
		flex-direction: column;
	}

	.threadStatus {
		display: flex;
		margin-left: auto;
		align-items: center;
		padding-left: 6px;
		padding-right: 1px;
		border: 1px solid rgba(25, 37, 46, 0.4);
		border-radius: 10px;
	}

	.mailItemPhotoThread {
		height: 32px;
		width: 32px;
		margin-left: 16px;
	}

	.avatarBackground {
		width: 36px;
		height: 36px;
		position: absolute;
		top: 0;

		svg {
			position: absolute;
		}
	}

	.mailItemUpperLine {
		display: flex;
		align-items: center;
	}

	.mailItemBottomLine {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
	}

	.mailDateAndSpec {
		margin-left: auto;
		height: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-shrink: 0;
	}

	.mailItemName {
		position: relative;
		height: 24px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 8px;
		font-size: 14px;
		line-height: 24px;
		color: #19252e;
	}

	.mailItemSubject {
		height: 24px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 8px;
		font-size: 14px;
		line-height: 24px;
		color: #19252e;
	}

	.mailItemDate {
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 0.35px;
		color: #596269;
		white-space: nowrap;
	}

	.flag {
		transition: width 0.13s ease;
		overflow: hidden;
		width: 0;

		&.active {
			width: 24px;
			margin-left: 4px;

			svg {
				opacity: 1;
			}
		}

		svg {
			flex-shrink: 0;
			opacity: 0;
			transition: opacity 0.15s ease;
		}
	}

	&:hover {
		.flag {
			width: 24px;
			margin-left: 4px;

			svg {
				opacity: 1;
			}
		}
	}
}

.mailListArray {
	& > div {
		&:first-of-type {
			border-top: 1px solid #e8e8e8;
		}
	}
}

.mailSecure {
	margin-right: 5px;
	cursor: pointer;

	.mailItemSigned {
		background-image: url('./../../assets/signedEmail.svg?inline');
		background-repeat: no-repeat;
		width: 16px;
		height: 16px;
	}

	.mailItemEncrypted {
		background-image: url('./../../assets/encryptedEmail.svg?inline');
		background-repeat: no-repeat;
		width: 16px;
		height: 16px;
	}
}

.img-wrapper-thread {
	position: relative;

	img {
		display: block;
		height: auto;
		height: 32px;
		width: 32px;
		border-radius: 50%;
	}
}

.img-wrapper {
	position: relative;
	height: 44px;

	img {
		display: block;
		max-width: 100%;
		height: auto;
	}

	svg {
		position: absolute;
	}
}

.svg-wrapper-thread {
	width: 32px;
	height: 32px;
	border-radius: 50%;

	svg {
		position: absolute;
		top: 4px;
		left: 4px;
	}
}

.svg-wrapper {
	width: 36px;
	height: 36px;
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
}

.mailStatus {
	position: relative;
	display: flex;
	align-items: center;
	width: 16px;
	min-width: 16px;
}

.mailItemSpecification {
	svg {
		margin-right: 4px;
	}
}

.threadSize {
	width: auto;
	min-width: 8px;
	font-family: Roboto !important;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #19252e;
	flex: none;
	order: 0;
	align-self: flex-start;
	flex-grow: 0;
	margin: 0px 0px;
}

.threadIcon {
	position: relative;
	width: 16px;
	height: 16px;
}

.highlight-subject {
	background: rgba(245, 141, 0, 0.16);
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
	color: rgba(0, 0, 0, 0.8);
}

.highlight-email {
	background: rgba(245, 141, 0, 0.16);
	font-style: normal;
	font-size: 14px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.8);
}

.dragMessageTooltip {
	bottom: -48px;
	position: absolute;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	/* identical to box height, or 114% */
	padding: 8px 12px;
	width: fit-content;
	/* basic/light/bg */
	filter: blur(0px);
	color: #ffffff;
	background: #19252e;
	/* 8px_down */

	box-shadow: 0px 4px 8px -4px rgba(25, 37, 46, 0.36), 0px 8px 8px -2px rgba(25, 37, 46, 0.16), 0px 0px 8px rgba(25, 37, 46, 0.12);
	border-radius: 2px;
}

.dragMessageWrap {
	width: fit-content;
	position: absolute;
	display: flex;
	transform: translate(0, 0);
	// height: auto;
	// filter: blur(0px); // width: fit-content;
	// background-color: rgba(0, 0, 0, 0) !important;
	flex-direction: column;
}

.dragMessageSecondLayer {
	position: absolute;
	top: 8px;
	left: 8px;
	height: 64px;
	background: #edeeee;
	z-index: 2;
	/* basic/light/fill/8 */

	border: 1px solid rgba(25, 37, 46, 0.08);
	/* float/8 */

	box-shadow: 0px 0px 8px rgba(25, 37, 46, 0.08), 0px 8px 8px -4px rgba(25, 37, 46, 0.08);
}

.dragMessageThirdLayer {
	position: absolute;
	z-index: 1;
	top: 16px;
	left: 16px;
	height: 64px;
	background: #edeeee;
	/* basic/light/fill/8 */

	border: 1px solid rgba(25, 37, 46, 0.08);
	/* float/8 */

	box-shadow: 0px 0px 8px rgba(25, 37, 46, 0.08), 0px 8px 8px -4px rgba(25, 37, 46, 0.08);
}

.dragMessageElement {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	padding: 12px 24px;
	width: fit-content;
	/* identical to box height, or 171% */

	/* basic/light/outline/100 */

	color: #19252e;
	background: #ffffff;
	/* basic/light/fill/8 */

	border: 1px solid rgba(25, 37, 46, 0.08);
}

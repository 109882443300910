.dark-tooltip {
	background: #28313a;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
	border-radius: 2px;
}

.MuiTooltip-popper {
	z-index: 10009 !important;
}

.MuiTooltip-tooltip{
	max-width: 500px !important;
	height: auto !important;
}
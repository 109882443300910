.FC-footer {
	width: 100%;
	height: 56px;
	min-height: 56px;
	background: #fff;
	position: sticky;
	bottom: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 24px;
	padding-right: 24px;
	border-top: 1px solid rgba(0, 0, 0, 0.16);
	margin-top: auto;
	& > p {
		cursor: default;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 24px;
		color: rgba(22, 33, 41, 0.92);
		margin: 0;
		margin-left: 8px;
	}
	& > svg {
		position: relative;
	}
}

.left-sidebar {
	.accountNavHeader {
		padding: 12px 0 0 16px;
		margin-bottom: 20px;

		.app-switch-anchor {
			background-color: transparent;
			height: auto;
			padding-left: 0;
			overflow: hidden;

			#as-toggle-logo, #as-toggle-button {
        cursor: pointer;
      }

			.app-switcher-choice-panel {
				z-index: 1;
			}
		}
	}

	.toolbar {
		padding: 0 16px;
		margin-bottom: 12px;

		.msg-button {
			font-family: 'Roboto';
			font-weight: 500;
			letter-spacing: 0.35px;
			color: #19252e;
			font-size: 14px;
			line-height: 24px;
			padding: 0 20px 0 14px;
			text-transform: none;
			box-shadow: none;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.msg-button,
		.msg-icon-button {
			background: rgba(255, 150, 46, 0.72);
			border: 1px solid rgba(25, 37, 46, 0.08);
			border-radius: 4px;
			width: 100%;
			height: 40px;
			text-align: center;
			cursor: pointer;
			transition: all 0.3s;

			.icon {
				display: flex;
				flex-shrink: 0;
			}
		}

		.text-container {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			display: block;
			position: relative;
		}
	}

	.account-card {
		width: 100%;
		padding: 0 0 0 16px;
		color: black;
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: row;
		transition: opacity 0.3s linear;

		.account-card-body {
			display: flex;
			flex-direction: column;
			width: 0;
			flex-grow: 1;
			justify-content: center;
			transition: opacity .3s linear;
		}
	}

	.list-item-name {
		text-overflow: ellipsis;
		display: block;
		overflow: hidden;
		white-space: nowrap;
		font-family: Roboto;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: #19252e;
	}

	.account-card-chevron {
		width: 24px;
		height: 24px;
		flex-shrink: 0;
		background: url(./sub-components/icons/ExpandMore.svg) no-repeat center;
		cursor: pointer;
		transition: all 0.3s;
	}

	.account-container {
		margin-bottom: 20px;
	}

	.account-card-email {
		margin-bottom: 4px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		font-family: Roboto;
		font-size: 14px;
		line-height: 24px;
		color: #596269;
		cursor: pointer;

		&:active {
			color: black;
		}
	}

	.settings-item {
		min-height: 32px;
		width: 100%;
		padding-left: 16px;
		color: black;
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: row;
		cursor: pointer;

		.settings-item-body {
			display: flex;
			flex-direction: column;
			width: 0;
			flex-grow: 1;
			justify-content: center;
		}
	}
	.navSettings {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow-y: auto;
		padding: 0;
		margin-top: 16px;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.list-item-button {
		padding-right: 0;

		&.Mui-selected {
			font-weight: 500;
			background: rgba(25, 37, 46, 0.16);

			&:hover {
				background: rgba(25, 37, 46, 0.16);
			}

			.list-item-name {
				font-weight: 500;
			}

			&.subitem {
				.list-item-name {
					font-weight: 700;
				}
			}
		}

		&.no-bg {
			background: transparent;

			&:hover {
				background: transparent;
			}
		}

		&.subitem {
			padding-left: 16px;
		}

		.account-card {
			padding: 0;

			.list-item-name {
				font-weight: 500;
			}
		}

		.account-warning {
			margin-right: 8px;
		}
	}

	&.minimized {
		.accountNavHeader {
			padding: 12px 8px 0 8px;
			.app-switch-anchor {
				justify-content: center;
				> p,
				> button {
					display: none;
				}
			}
		}

		.toolbar {
			padding: 0 4px;
			display: flex;
			align-items: center;
			justify-content: center;

			.msg-icon-button {
				width: 40px;
			}
			.msg-button {
				width: 40px;
				padding: 0;
			}
		}

		.account-card {
			padding: 0;
			flex-direction: column;
			align-items: center;

			.account-warning {
				margin-right: 0;
			}
		}

		.account-card-body {
			width: auto;
		}

		.settings-item {
			padding-left: 8px;
			padding-right: 8px;
		}

		.list-item-button {
			display: flex;
			justify-content: center;

			&.account {
				padding-left: 0;
			}

			&.subitem {
				padding-left: 8px;
				padding-right: 8px;
			}
		}

		.toggle-btn {
			padding: 0;
		}
	}
}

.brush-icon {
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	background: url(./sub-components/icons/brush.svg) no-repeat center;
}

.account-warning {
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	margin-right: 15px;
	background: url(./sub-components/icons/warning.svg) no-repeat center;
	cursor: pointer;
}

.account-warning-paper {
	border-radius: 20px;

	.line-menu {
		background: rgba(25, 37, 46, 0.08);
		border-radius: 20px;
		height: 32px;
		padding: 4px 4px 4px 8px;
		display: flex;
		overflow: hidden;
	}

	.account-warning {
		margin-right: 0;
		margin-left: 15px;
		border-radius: 50%;
		transition: background-color 0.3s linear;

		&:hover {
			background: url(./sub-components/icons/warning.svg) no-repeat center,
								rgba(25, 37, 46, 0.16);
		}
	}

	.list-item-name{
		cursor: default;
	}
}

.notification-container {
	position: relative;

	&::after {
		content: '';
		display: block;
		position: absolute;
		width: 6px;
		height: 6px;
		background-color: #cc7a00;
		border-radius: 50%;
		top: -4px;
		right: -4px;
	}
}
.SharingGrantedLine {
  width: 640px;
  margin-top: 24px;
  border-bottom: 1px solid rgba(25, 37, 46, 0.16);


  &:last-of-type  {
    border-bottom: none;
  }

  .header {
    width: 100%;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    p {
      margin: 0;
      margin-left: 8px;
      color: #000;
      /* Body2/medium */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
    }

    button.icon-button {
      border: none;
      background: #fff;
      outline: none;
      width: 32px;
      height: 32px;
      padding: 4px;
      display: flex;
      border-radius: 50%;
      &:hover {
        background: rgba(25, 37, 46, 0.08);
      }
    }
  }

  .emailAndFolderSpec {
    width: 100%;

    padding-left: 40px;
    display: flex;
    flex-direction: column;

    & > p:nth-of-type(1) {
      margin: 0;
      color: #000;
      /* Body2/regular */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
    }
    & > p:nth-of-type(2) {
      overflow: hidden;
      width: 430px;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    & > p:nth-of-type(2),
    & > p:nth-of-type(3) {
      margin: 0;
      margin-top: 4px;
      color: rgba(25, 37, 46, 0.72);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
    }

    & > p:last-of-type{
      margin-bottom: 22px;
    }
  }
}

@import '/src/assets/variables.scss';

.modalHeader {
	height: 56px;
	padding-left: 16px;
	padding-top: 16px;
	width: 100%;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: rgba(0, 0, 0, 0.8);
}
.modalFooter {
	width: 100%;
	position: relative;
	height: 56px;
	display: flex;
	padding-right: 16px;
	padding-left: 16px;
	align-items: center;

	.cancel {
		display: inline-block;
		margin-left: auto;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		cursor: pointer;
		color: rgba(22, 31, 41, 0.8);
	}

	.saveButton {
		height: 32px;
		padding-left: 12px;
		padding-right: 12px;
		background: #f58d00;
		color: #ffffff;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 20px;
		border-radius: 4px;
		border: none;
		&:disabled {
			border: none;
		}
		&:focus {
			border: none !important;
			outline: none !important;
		}
	}
}

.recallBody {
	width: 100%;
	height: 229px;
	display: flex;
	padding: 0 16px;
	flex-direction: column;
	//For FF
	scrollbar-width: thin;
	scrollbar-color: $light-grey transparent;
	
	.recallInfo {
		width: 100%;
		height: fit-content;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		color: #000000;
	}
	.recallList {
		height: auto;
		margin-top: 16px;
		overflow-y: scroll;
		width: 100%;
		scrollbar-width: thin;
		&::-webkit-scrollbar {
			display: block;
			width: 6px;
		}
		&::-webkit-scrollbar-track {
			box-shadow: unset;
			background-color: white;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: rgba(0, 0, 0, 0.16);
		}
	}
	.recallForce{
		margin-top: auto;
	}
}

.notifyResult {
	position: absolute;
	left: 12px;
	top: 16px;
}
.notifyResultText {
	position: absolute;
	left: 44px;
	top: 19px;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	max-width: 239px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #000000;
}
.textButton {
	border: none;
	height: 20px;
	padding: 0 !important;
	background: #ffffff !important;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #db7e00;
	&:focus {
		border: none !important;
		outline: none;
	}
}
.btn-secondary:focus {
	box-shadow: none !important;
	background-color: white !important;
	border: none !important;
}

#headers-modal-inner-text {
	word-wrap: break-word;
}

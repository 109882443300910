@font-face {
	font-family: 'PT Sans';
	font-display: swap;
	font-style: normal;
	src: url('../assets/fonts/PT_Sans/PTSans-Regular.ttf');
}

@font-face {
	font-family: 'PT Sans';
	font-display: swap;
	font-style: normal;
	font-weight: bold;
	src: url('../assets/fonts/PT_Sans/PTSans-Bold.ttf');
}

@font-face {
	font-family: 'PT Sans';
	font-display: swap;
	font-style: italic;
	src: url('../assets/fonts/PT_Sans/PTSans-Italic.ttf');
}

@font-face {
	font-family: 'PT Sans';
	font-display: swap;
	font-style: italic;
	font-weight: bold;
	src: url('../assets/fonts/PT_Sans/PTSans-BoldItalic.ttf');
}

@font-face {
	font-family: 'Roboto';
	font-display: swap;
	font-style: normal;
	src: url('../assets/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
	font-family: 'Roboto';
	font-display: swap;
	font-style: normal;
	font-weight: 500;
	src: url('../assets/fonts/Roboto/Roboto-Medium.ttf');
}

@font-face {
	font-family: 'Roboto';
	font-display: swap;
	font-style: normal;
	font-weight: bold;
	src: url('../assets/fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
	font-family: 'Roboto';
	font-display: swap;
	font-style: italic;
	src: url('../assets/fonts/Roboto/Roboto-Italic.ttf');
}

@font-face {
	font-family: 'Roboto';
	font-display: swap;
	font-style: italic;
	font-weight: bold;
	src: url('../assets/fonts/Roboto/Roboto-BoldItalic.ttf');
}

@font-face {
	font-family: 'XO Tahion';
	font-display: swap;
	font-style: normal;
	src: url('../assets/fonts/XO_Tahion/XO_Tahion_Nu.ttf');
}

@font-face {
	font-family: 'XO Tahion';
	font-display: swap;
	font-weight: bold;
	src: url('../assets/fonts/XO_Tahion/XO_Tahion_bold.ttf');
}

@font-face {
	font-family: 'XO Oriel';
	font-display: swap;
	font-style: normal;
	src: url('../assets/fonts/XO_Oriel/XO_Oriel_Nu.ttf');
}

@font-face {
	font-family: 'XO Oriel';
	font-display: swap;
	font-style: italic;
	src: url('../assets/fonts/XO_Oriel/XO_Oriel_Ni.ttf');
}

@font-face {
	font-family: 'XO Oriel';
	font-display: swap;
	font-stretch: condensed;
	font-style: normal;
	src: url('../assets/fonts/XO_Oriel/XO_Oriel_Ni.ttf');
}

@font-face {
	font-family: 'XO Oriel';
	font-display: swap;
	font-weight: bold;
	font-style: normal;
	src: url('../assets/fonts/XO_Oriel/XO_Oriel_Bu.ttf');
}

@font-face {
	font-family: 'XO Oriel';
	font-display: swap;
	font-weight: bold;
	font-style: italic;
	src: url('../assets/fonts/XO_Oriel/XO_Oriel_Bi.ttf');
}

@font-face {
	font-family: 'XO Trebizond';
	font-display: swap;
	src: url('../assets/fonts/XO_Trebizond/XO_Trebizond_Nu.ttf');
}

@font-face {
	font-family: 'XO Courser';
	font-display: swap;
	font-weight: bold;
	src: url('../assets/fonts/XO_Courser/XO_Courser_B.ttf');
}

@font-face {
	font-family: 'XO Courser';
	font-display: swap;
	font-weight: bold;
	font-style: italic;
	src: url('../assets/fonts/XO_Courser/XO_Courser_BI.ttf');
}

@font-face {
	font-family: 'XO Courser';
	font-display: swap;
	font-weight: normal;
	font-style: italic;
	src: url('../assets/fonts/XO_Courser/XO_Courser_NI.ttf');
}

@font-face {
	font-family: 'XO Courser';
	font-display: swap;
	font-weight: normal;
	src: url('../assets/fonts/XO_Courser/XO_Courser_Nu.ttf');
}

@font-face {
	font-family: 'XO Verbena';
	font-display: swap;
	font-weight: normal;
	src: url('../assets/fonts/XO_Verbena/XO_Verbena_Nu.ttf');
}

@font-face {
	font-family: 'XO Thames';
	font-display: swap;
	font-weight: normal;
	font-style: italic;
	src: url('../assets/fonts/XO_Thames/XO_Thames_Ni.ttf');
}

@font-face {
	font-family: 'XO Thames';
	font-display: swap;
	font-weight: normal;
	src: url('../assets/fonts/XO_Thames/XO_Thames_Nu.ttf');
}

@font-face {
	font-family: 'XO Thames';
	font-display: swap;
	font-weight: bold;
	src: url('../assets/fonts/XO_Thames/XO_Thames_Bu.ttf');
}

@font-face {
	font-family: 'XO Thames';
	font-display: swap;
	font-weight: bold;
	font-style: italic;
	src: url('../assets/fonts/XO_Thames/XO_Thames_BI.ttf');
}

@font-face {
	font-family: 'PT Astra Sans';
	font-display: swap;
	font-weight: bold;
	font-style: italic;
	src: url('../assets/fonts/PT_Astra_Sans/PT_Astra_Sans_Bold_Italic.ttf');
}

@font-face {
	font-family: 'PT Astra Sans';
	font-display: swap;
	font-style: italic;
	src: url('../assets/fonts/PT_Astra_Sans/PT_Astra_Sans_Italic.ttf');
}

@font-face {
	font-family: 'PT Astra Sans';
	font-display: swap;
	font-weight: bold;
	src: url('../assets/fonts/PT_Astra_Sans/PT_Astra_Sans_Bold.ttf');
}

@font-face {
	font-family: 'PT Astra Sans';
	font-display: swap;
	src: url('../assets/fonts/PT_Astra_Sans/PT_Astra_Sans_Regular.ttf');
}

@font-face {
	font-family: 'PT Astra Serif';
	font-display: swap;
	font-weight: bold;
	font-style: italic;
	src: url('../assets/fonts/PT_Astra_Serif/PT_Astra_Serif_Bold_Italic.ttf');
}

@font-face {
	font-family: 'PT Astra Serif';
	font-display: swap;
	font-style: italic;
	src: url('../assets/fonts/PT_Astra_Serif/PT_Astra_Serif_Italic.ttf');
}

@font-face {
	font-family: 'PT Astra Serif';
	font-display: swap;
	font-weight: bold;
	src: url('../assets/fonts/PT_Astra_Serif/PT_Astra_Serif_Bold.ttf');
}

@font-face {
	font-family: 'PT Astra Serif';
	font-display: swap;
	src: url('../assets/fonts/PT_Astra_Serif/PT_Astra_Serif_Regular.ttf');
}
.error-box {
	max-width: 400px;
	padding: 12px 16px;
	background-color: #fff0f0;

	&__header {
		display: flex;
		align-items: flex-start;
		font-family: Roboto;
		margin-bottom: 12px;
		font-size: 16px;
		line-height: 24px;
	}

	&__title {
		margin-left: 8px;
	}

	&__btn {
		padding: 0 16px;
		font-family: Roboto;
		font-size: 14px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0.35px;
		color: #006fae;
	}
}
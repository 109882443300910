.sharingSettings {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 12px;

  .header {
    & > h2 {
      color: #000;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
      margin-bottom: 4px;
    }

    & > h3 {
      color: rgba(22, 31, 41, 0.6);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      margin-bottom: 0px;
    }

    & > button {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;

      padding: 4px 12px;
      width: fit-content;
      border-radius: 4px;
      border: 1px solid rgba(25, 37, 46, 0.4);
      background-color: #fff;

      color: #19252e;
      /* Specific/button_normal */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.35px;

      &:hover {
        background-color: rgb(237, 238, 239);
      }
    }
  }

  .body {
    margin-top:32px;
    & > h3 {
      margin: 0;
      color: #000;
      /* h3 */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 125% */
    }
  }
}
.sharingDeleteDialogRoot {

  .deleteDialogContainer {

    .deletePaper {
      min-width: 350px;

      .loaderBackground {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgb(255 255 255 / 40%);
      }

      .deleteDialog {
        width: 350px;
        height: 100%;
        padding: 8px 0px;
        min-height: 136px;

        .sharingErrors {
          display: flex;
          border-radius: 4px;
          margin: 0px 12px 8px 12px;
          padding: 12px 16px;
          width: 326px;
          height: 72px;
          background-color: rgba(255, 240, 240, 1);

          .sharingErrorIcon {
            margin-right: 6px;
            width: 20px;
            height: 20px;
          }
          .errorText {
            max-width: 225px;
            align-self: center;
            margin: 0;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
          }
        }

        .deleteFooter {
          padding-bottom: 0px !important;
          margin-top:8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .saveButton {
            &:disabled {
              background-color: #e2333a8a;

            }
          }

          .cancelButton {
            &:disabled {
              color: #19252e9c;
            }
          }
        }
      }
    }
  }
}


.single-composer-body {
	.single-composer-field {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		overflow-x: visible;
		overflow-y: scroll;
		position: relative;
		
		.fr-toolbar .fr-command.fr-btn svg {
			width: 24px !important;
			height: 24px !important;
			position: relative;
		}
		&::-webkit-scrollbar {
			display: none;
		}
	}
	blockquote {
		border-left: solid 2px #000000 !important;
		margin: 4px 2px !important;
		padding-left: 6px !important;
		div {
			direction: ltr;
			white-space: normal;
			color: black;
		}
	}
	html {
		&::-webkit-scrollbar {
			display: none;
			-ms-overflow-style: none;
			scrollbar-width: none;
		}
	}
}

.securityLine {
	padding: 12px 24px;
	font-size: 14px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.8);
	display: flex;
	flex-direction: row;
	&.securityLineHdden {
		height: 0;
		min-height: 0px;
		opacity: 0;
		padding: 0;
		margin: 0;
	}
	&.securityLineVisible {
		min-height: 44px;
		opacity: 1;
		transition: opacity 150ms ease 0s, min-height 150ms ease 0s;
	}
	div {
		margin-right: 8px;
	}
	.icon {
		padding-left: 24px;
		background-position: 0px -2px !important;
		&.sign {
			background: url('./../../../../../../assets/sign.svg?inline') no-repeat;
		}
		&.encrypt {
			background: url('./../../../../../../assets/encrypt.svg?inline') no-repeat;
		}
	}
}

@import './DatePicker.scss';
@import './fonts.css';

#root {
	height: 100vh;
}

.data {
	width: 358px !important;
	top: 4px !important;
}

.panel.calendar_manager .panel_bottom {
	position: relative;
	top: -60px;
}

html,
body {
	height: 100%;
}

body {
	overflow: hidden;
}

.svgIcon {
	width: 24px;
	height: 24px;
	font-size: 24px;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}

.si-emptyCheck {
	background-image: url('../assets/emptyCheckBox.svg?inline');
}

.si-checkedBox {
	background-image: url('../assets/checkedBox.svg?inline');
}

#root .dropdown {
	display: block !important;
	opacity: 1 !important;
	left: inherit !important;
	z-index: auto !important;
}

#root table .row {
	height: auto;
}

#root #mailContent ol li,
#root #mailContent ul li {
	list-style-type: inherit;
}

.dropdown-item {
	overflow: hidden;
	padding-right: 8px !important;
	text-overflow: ellipsis;
}

.bg-none {
	background-color: transparent !important;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 50px white inset;
}

// Исправляет тряску тултипа и меню после transition
.MuiTooltip-tooltip, .MuiPaper-root {
	backface-visibility: hidden;
}

@media screen and (max-width: 959px) {
	#root {
		overflow: auto;
	}
}
.WideNotification {
  width: 790px;
  height: 64px;
  display: flex;
  position: relative;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  background: #ffffff;
}
.WideNotificationIcon {
  width: 24px;
  height: 24px;
  position: relative;
  top: 14px;
  left: 12px;
}
.WideNotificationBody {
  position: absolute;
  left: 40px;
  top: 16px;
  max-width: 715px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
}
.WideNotificationClose {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 14px;
  right: 14px;
}

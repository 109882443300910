.LargeNotification {
	width: 403px;
	height: 176px;
	display: flex;
	position: relative;
}
.LargeNotificationIcon {
	width: 24px;
	height: 24px;
	position: relative;
	top: 16px;
	left: 12px;
}
.LargeNotificationHeader {
	height: 24px;
	display: inline-block;
	position: relative;
	top: 16px;
	margin-left: 12px;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}
.LargeNotificationBody {
	position: absolute;
	left: 12px;
	top: 56px;
	max-width: 378px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}
.LargeNotificationAccept {
	position: relative;
	display: inline-flex;
	height: 32px;
	left: -140px;
	top: 132px;
	cursor: pointer;
	border-radius: 4px;
	div {
		cursor: pointer;
		position: relative;
		display: inline-block;
		height: 20px;
		margin-right: 12px;
		margin-left: 12px;
		margin-top: 6px;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 20px;
		color: #ffffff;
	}
}
.LargeNotificationCancel {
	position: relative;
	margin-left: -128px;
	height: 20px;
	top: 138px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
}

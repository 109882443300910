#composer-container {
	width: auto;
	max-width: 1040px;
	height: 90%;
	max-height: 800px;
	overflow: hidden;
	border-radius: 12px;
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 0px;
}

.MuiCircularProgress-colorSecondary {
	color: #a9a9a9 !important;
}
.MuiOutlinedInput-root {
	&.Mui-focused {
		.MuiOutlinedInput-notchedOutline {
			border-color: #19252EB8;;
		}
	}
}
.paginationFooter {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 56px;
	position: absolute;
	width: 100%;
	bottom: 0px;
	border-top: 1px solid rgba(25, 37, 46, 0.16);
	* {
		cursor: pointer !important;
	}
}
.pageNumber {
	position: relative;
	height: 24px;
	font-family: Roboto !important;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #19252E;
}
.pageIcon {
	width: 32px;
	height: 32px;
	min-width: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	&:hover {
		background-color: rgba(1, 1, 1, 0.1);
	}
}
.pageIconActive {
	background: url('./activePage.svg?inline') !important;
}
#firstPage {
	width: 24px;
	height: 24px;
	position: relative;
	margin-right: 8px;
	cursor: pointer;
	display: flex;
	svg {
		position: relative;
	}
}
#previousPage {
	width: 24px;
	height: 24px;
	position: relative;
	cursor: pointer;
	display: flex;
	svg {
		position: relative;
	}
}
#nextPage {
	width: 24px;
	height: 24px;
	position: relative;
	cursor: pointer;
	display: flex;
	svg {
		position: relative;
	}
}
#lastPage {
	width: 24px;
	height: 24px;
	margin-left: 8px;
	position: relative;
	cursor: pointer;
	display: flex;
	svg {
		position: relative;
	}
}
.LinkPopUpLine {
	height: 56px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	p {
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 24px;
		color: #000000;
		margin-bottom: 0px;
	}
	div {
		margin-top: 8px;
		height: 24px;
		width: 100%;
		display: flex;
		flex-direction: row;
		input {
			width: 343px;
			border: none;
			border-bottom: 1px solid  rgba(25, 37, 46, 0.16);
			height: 24px;
			padding: 0px;
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			color: #19252E;
			&:focus {
				outline: none !important;
			}
		}
		button {
			width: 24px !important;
			height: 24px;
			margin-left: auto;
			background-image: url('./icons/cancel.svg') !important;
			background-position: center;
			background-repeat: no-repeat;
			outline: none !important;
		}
	}
}

.mail-label {
  display: flex;
  width: 0;
  flex-grow: 1;

  .mail-label__title {
    font-size: 14px;
    font-weight: 400;
    margin-left: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.IOSProfile{
  font-family: PT Sans;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;

  h4{
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
  }

  .subheader{
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  .MuiFormControlLabel-label{
    font-size: 14px;
    line-height: 20px;
  }

  .btn-yellow{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    padding: 4px 8px;
    text-transform: none;
    color: rgba(255, 255, 255, 0.92);
    background-color: #F58D00;
    border-radius: 4px;
    display: block;
    margin: 0 auto 20px auto;

    &.Mui-disabled{
      background-color: rgba(245, 141, 0, 0.44);
    }
  }

  .btn-white{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    padding: 4px 8px;
    text-transform: none;
    display: block;
    margin: 0 auto 20px auto;
    border: unset;
  }

  .text-muted{
    font-size: 14px;
    line-height: 20px;
    color: rgba(22, 33, 41, 0.48);
  }
  
  .form-control-label{
    margin: 0 0 8px 0;
  }

  .yellow-checkbox{
    color: rgb(25, 37, 46);
    min-height: 24px;
    min-width: 24px;
    padding: 3px 3px 4px 4px;


    .MuiIconButton-label{
      position: relative;
      z-index: 0;

      &::after {
        content: "";
        left: 3px;
        // top: 3px;
        height: 15px;
        width: 15px;
        position: absolute;
        border-radius: 4px;
        background-color: rgba(25, 37, 46, 0.08);
        z-index: -1;
      }
    }

    .MuiSvgIcon-root {
      height: 21px;
      width: 21px;
    }

    &.checked{
      color: rgb(255, 150, 46);
      background-color: transparent;

      .MuiIconButton-label:after {
        opacity: 1;
        background-color: "#19252E";
      }
    }
  }
}
.mailSettings {
	height: calc(100% - 56px);
	overflow-y: scroll;
	width: 100%;
	overflow-x: hidden;
	scroll-behavior: smooth;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}
.subSettingsChainsTitle {
	position: relative;
	height: 24px;
	margin-top: 16px;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}
.subSettingsChainsCheckbox {
	position: relative;
	width: 24px;
	display: inline-block;
	height: 24px;
	margin-top: 8px;
	& > svg {
		cursor: pointer;
	}
}
.subSettingsChainsHint {
	position: relative;
	display: inline-block;
	height: 24px;
	top: -7px;
	margin-left: 8px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #161f29;
}
.addMailButton {
	height: 32px;
	padding: 6px 12px;
	border: 1px solid #f49200;
	color: #f49200;
	background: #ffffff;
	cursor: pointer !important;
	border-radius: 2px;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 20px;
	&:focus {
		outline: none !important;
	}
}

.autoInput {
	margin-top: 3px;
	font-family: Roboto !important;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: #2B363F;

	.composer-autocomplete-input {
		max-height: 70px;
		overflow-y: scroll;
		
		&::-webkit-scrollbar {
			width: 6px !important;
			background-color: #ffffff !important;
		}
		
		&::-webkit-scrollbar-track {
			border: none !important;
			display: none;
		}
		
		&::-webkit-scrollbar-thumb {
			border-radius: 3px !important;
			background: rgba(0, 0, 0, 0.16) !important;
		}

		.autoInputRoot {
			padding: 1px 2px;
			padding-left: 5px;
			min-height: 26px;
		}
	}
}

.autoInputRootClass {
	background: transparent !important;
	padding: 0 !important;
	margin: 0 !important;
}

.autoInputAutocompleteFocused .autoInputRootClass:before,
.autoInputAutocompleteFocused .autoInputRootClass:hover:before,
.autoInputAutocompleteFocused .autoInputRootClass:after {
	border-bottom: 1px solid rgba(0, 0, 0, 0.16) !important;
}

.autoInputRootClass:before,
.autoInputRootClass:after {
	border-color: rgba(0, 0, 0, 0.16) !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.16) !important;
}

.autoInputPopper{
	.autoInputListbox{
		max-height: 172px;
		overflow-x: hidden;

		.autoInputOption{
			padding: 2px 12px;

			.optionAvatar {
				width: 32px;
				height: 32px;
				border-radius: 50%;
				margin-right: 10px;
			
				img {
					width: 32px;
					height: 32px;
					border-radius: 50%;
				}
			}

			.info,
			.optionName,
			.optionEmail{
				overflow-x: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.optionName {
				height: 24px;
				font-size: 16px;
				line-height: 24px;
			}

			.suggest-position{
				margin-left: 6px;
				color: #596269;
			}

			.optionEmail {
				font-size: 14px;
				line-height: 20px;
				color: rgba(22, 31, 41, 0.64);
			}
		}
	}
}

.optionAccountPill {
	cursor: pointer;
	height: 22px !important;
	margin-left: 8px !important;
	background: rgba(22, 31, 41, 0.08) !important;
	border: 1px solid rgba(22, 31, 41, 0.16) !important;
	border-radius: 12px !important;
	box-sizing: content-box !important;
	max-width: calc(100% - 11px) !important;

	.optionAvatar {
		width: 24px;
		height: 24px;
		margin-right: 0px;
		margin-left: 0px !important;

		img {
			width: 24px;
			height: 24px;
		}
	}

	.chip-name {
		overflow: hidden;
    text-overflow: ellipsis;
	}

	.autocomplete-label {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&.noPadding {
			padding-left: 0px !important;
		}
	}

	span {
		&.icon {
			width: 24px;
			height: 24px;
			position: relative;

			&.valid {
				svg {
					top: 4px;
					left: 6px;
				}
			}
			&.inValid {
				svg {
					left: 2px;
				}
			}
		}
	}
}

.optionCertificateExist {
	background: url('../../../../../../../../../../assets/certificateExist.svg?inline') no-repeat center;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	// position: absolute;
	margin-left: auto;
	z-index: 1;
}
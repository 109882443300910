.calendarSettings {
	height: calc(100% - 56px);
	overflow-y: scroll;
	width: 100%;
	overflow-x: hidden;
	scroll-behavior: smooth;
	h6 {
		font-weight: bold;
	}
	&::-webkit-scrollbar {
		display: none;
	}
	.timeZoneSetting {
		.settingCollpase {
			.dropdown-menu {
				-ms-overflow-style: scrollbar !important;
				scrollbar-width: auto !important;
				&::-webkit-scrollbar {
					display: inherit !important;
				}
			}
		}
	}

	.settingsCollapseTitle {
		display: flex;
		align-items: center;

		svg {
			position: static;
		}
	}
}

.smallCollapse {
	display: inline-block;
	.settingCollpase {
		.settingsCollapseTitle{
			width: auto;
		}

		.settingsCollapseChoice {
			width: calc(100% - 24px);
		}
		.dropdown-menu {
			left: -113px !important;
			width: 150px !important;
			min-width: 150px !important;
			.dropdown-item {
				width: 150px !important;
				margin-right: 0 !important;
			}
		}
	}
}
.commonSettings {
	height: calc(100% - 56px);
	overflow-y: scroll;
	width: 100%;
	scroll-behavior: smooth;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
	.link {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		color: #006FAE;
	}
}
.commonSubSettings {
	width: 100%;
}
.syncSubSettings {
	height: auto;
	width: 100%;
}
.exchangeSubSettings {
	height: 550px;
	width: 100%;
}
.subSettingTitle {
	font-family: 'Roboto';
	position: relative;
	height: 24px;
	margin-top: 16px;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}
.subSettingsNotificationsCheckbox {
	position: relative;
	width: 24px;
	display: inline-block;
	height: 24px;
	margin-top: 8px;
	cursor: pointer !important;
}
.subSettingsNotificationsHint {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	display: inline-block;
	height: 24px;
	top: -7px;
	margin-left: 8px;
	color: #161f29;
}

.inputCopyWrap {
	align-items: center;
	display: flex;
	flex-direction: row;
}
.svgForCopy {
	display: inline-block;
	width: 32px;
	height: 32px;
	vertical-align: sub;
	margin-left: 2px;
	background-image: url('./svg/copylink.svg?inline');
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer !important;
	&:hover {
		border-radius: 50%;
		background-color: rgba(22, 31, 41, 0.08);
	}
}
.importButton {
	height: 32px;
	padding: 6px 12px;
	border: 1px solid #f49200;
	color: #f49200;
	background: #ffffff;
	cursor: pointer !important;
	border-radius: 2px;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 20px;
	&:focus {
		outline: none !important;
	}
	&:disabled {
		opacity: 0.4;
	}
}
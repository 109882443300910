$input-border-color: hsla(206, 30%, 14%, 0.4);
$input-error-color: hsla(358, 64%, 53%, 1);

.filter-dialog-paper {
	border-radius: 12px;
}
.filter-modal {
	display: flex;
	flex-direction: column;
	height: 560px;
	width: 560px;
	position: relative;
	.modalHeader {
		padding: 24px 0 12px 24px;
		// display: flex;
		// flex-direction: row;
		// justify-content: space-between;
		max-width: none;

	}
	.filter-close-button{
		position: absolute;
		padding: 4px;
    right: 12px;
    top: 12px;
	}

}
.filterBody {
	font-family: 'Roboto';
	width: 100%;
	padding: 16px 24px 24px 24px;
	display:flex;
	flex-direction: column;
	gap: 24px;
	flex: 1;

	.filterError {
		color: $input-error-color;
		font-size: 14px;
		line-height: 24px;
	}
	.modal_checkbox-line{
		display: flex;
		flex-direction: row;
	}
}


.filterInput {
	width: 100%;
	height: 33px;
	padding-left: 11px;
	font-size: 14px;
	line-height: 24px;
	border-radius: 4px;
	border: 1px solid $input-border-color;	
	&:focus {
		outline: none !important;
	}

	&-error{
		border-color: $input-error-color;
	}

}
.input-wrapper {
	width: calc(100% - 216px);
}

.textarea-wrapper {
	width: 100%;
}
.filter-textarea {
	padding: 8px;
	width: 100%;
	resize: none;
	font-size: 14px;
	line-height: 24px;
}

.modal-block {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.modal-block_header {
		font-weight: 500;
	}
	.modal-block_line {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		gap: 12px;
		> *:first-child:not(.textarea-wrapper) {
			width: 200px;
			
		}


	}

	

	.MuiAutocomplete-inputRoot.MuiAutocomplete-inputRoot {
		font-size: 14px;
		padding-top: 4px;
		padding-bottom: 4px;
		cursor: pointer !important;
		
		fieldset {
			border-color: $input-border-color;
			border-radius: 4px;
		}
		*::selection {
			background-color: transparent !important;
		}
		* {
			cursor: pointer !important;
		}
	}
}

.filters-listbox{
	.MuiAutocomplete-option.MuiAutocomplete-option{
		background-color: rgba(0, 0, 0, 0);

		&.Mui-focused{
			background-color: rgba(0, 0, 0, 0.04);
		}
	}
}

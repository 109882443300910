#mailInstructionsModal .instructionsPaper {
  margin-left: 24px;
  margin-top: 4px;
}

#mailInstructionsModal {
  .instructions {
    width: 340px;
    height: 226px;
    display: flex;
    flex-direction: column;
    padding: 0 16px 16px 16px;

    .instructionsHeader {
      padding: 8px 0;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .closeInstructionsButton {
        padding: 0;
      }
    }

    .instructionsBody {

      .instructionText {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #19252E;
        margin-bottom: 0;
      }

      .instructionsList {
        padding-left: 0;
        a {
          text-decoration: underline;
        }
      }
    }
  }
}
.smallComposer {
	width: 502px;
	height: 242px;
	border: 1px solid rgba(22, 31, 41, 0.08);
	.quill {
		width: 500px !important;
		height: 200px !important;
		background: #ffffff;
		position: relative;
		overflow-y: hidden;
	}
	.ql-container {
		&.ql-snow {
			border: none;
		}
	}
	.composerToolbarWrapper {
		position: relative;
	}
	#fonts {
		width: 120px;
		padding-left: 8px;
		display: flex;
		align-items: center;
		position: relative;
		.fontDrop {
			width: 24px !important;
			height: 24px !important;
			margin-left: 18px !important;
		}
	}
	#fontTitle {
		position: relative;
		width: 70px;
		height: 24px;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		white-space: nowrap;
	}
	#fontSize {
		width: 58px;
		padding-left: 8px;
		display: flex;
		align-items: center;
		position: relative;
		.fontSizeDrop {
			width: 24px !important;
			height: 24px !important;
			margin-left: 8px !important;
		}
	}
	#fontSizeTitle {
		position: relative;
		width: 18px;
		height: 24px;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		white-space: nowrap;
	}
	#fontColor {
		width: 48px;
		display: flex;
		align-items: center;
		position: relative;
	}
	#fontColorTitle {
		width: 16px;
		height: 24px;
		position: relative;
	}
	.fontDrop {
		button {
			width: 24px;
			height: 24px;
			border: none;
		}
		.dropdown-menu {
			width: 199px !important;
			height: 336px !important;
			position: absolute;
			left: 88px !important;
			top: 5px !important;
			border-radius: 4 !important;
			box-shadow: 0 0 8px #00000016, 8px 0 16px -2px #00000016, 8px 0 6px -4px #00000024 !important;
			.dropdown-item {
				padding-left: 12px !important;
			}
		}
		.dropdown-item {
			width: 100% !important;
			height: 40px !important;
			padding-left: 12px !important;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			&:hover {
				color: black !important;
				padding-left: 12px !important;
			}
		}
		.btn-secondary {
			box-shadow: none !important;
			background-color: white !important;
			border: none !important;
		}
	}
	.fontSizeDrop {
		button {
			width: 24px;
			height: 24px;
			border: none;
		}
		.dropdown-menu {
			width: 101px !important;
			min-width: 101px !important;
			height: 216px !important;
			position: absolute;
			left: 42px !important;
			top: 5px !important;
			border-radius: 4px !important;
			box-shadow: 0 0 8px #00000016, 8px 0 16px -2px #00000016, 8px 0 6px -4px #00000024 !important;
			.dropdown-item {
				padding-left: 12px !important;
			}
		}
		.dropdown-item {
			width: 100% !important;
			height: 40px !important;
			padding-left: 12px !important;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			&:hover {
				color: black !important;
				padding-left: 12px !important;
			}
		}
		.btn-secondary {
			box-shadow: none !important;
			background-color: white !important;
			border: none !important;
		}
	}
	.dropdown-menu {
		.dropdown-item {
			&:hover {
				background: rgb(235, 235, 235) !important;
			}
		}
	}
	#colorWrapper {
		width: 400px;
		display: flex;
		height: 227px;
		box-shadow: 0 0 8px #00000016, 8px 0 16px -2px #00000016, 8px 0 6px -4px #00000024 !important;
		border-radius: 2px;
		position: absolute;
		top: 32px;
		left: -376px;
	}
}
.dropdown-toggle {
	&::after {
		display: none;
	}
}
.smallComposer .fontSizeDrop .btn-secondary.focus,
.btn-secondary:focus {
	box-shadow: none !important;
	background-color: white !important;
	border: none !important;
}
.smallComposer .fontDrop .btn-secondary.focus,
.btn-secondary:focus {
	box-shadow: none !important;
	background-color: white !important;
	border: none !important;
}
.ql-font-PTAstraSerif {
	font-family: 'PT Astra Serif';
}
.ql-font-PTAstraSans {
	font-family: 'PT Astra Sans';
}
.ql-font-XOThames {
	font-family: 'XO Thames';
}
.ql-font-XOVerbena {
	font-family: 'XO Verbena';
}
.ql-font-XOTrebizond {
	font-family: 'XO Trebizond';
}
.ql-font-XOTahion {
	font-family: 'XO Tahion';
}
.ql-font-XOOriel {
	font-family: 'XO Oriel';
}
.ql-font-XOCourser {
	font-family: 'XO Courser';
}
.smallComposerWrapper {
	margin-top: 15px;

	.subSettingsHeader {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 28px;
		color: #000;
	}
}
.composerSection {
	width: 500px;
	margin-top: 8px;
	display: inline-block;
	position: relative;
	vertical-align: top;
	padding-left: 16px;
}

#signature {

	.ql-tooltip {
		left: 0px !important
	}
}
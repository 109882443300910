.choose-all {
	width: 100%;
	height: 48px;
	display: flex;
	flex-direction: row;
	align-items: center;
	border: 1px solid rgba(25, 37, 46, 0.16);
	box-shadow: 0px 0px 8px rgba(25, 37, 46, 0.08), 0px 8px 8px -4px rgba(25, 37, 46, 0.08);
	border-radius: 4px;
	padding: 0 12px;
	margin-bottom: 1px;

	&__btn {
		width: 100%;
		font-size: 14px;
		line-height: 18px;

		&:hover {
			box-shadow: none;
		}
	}

	&__btn-cancel {
		margin-left: 8px;
	}
}

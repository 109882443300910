
.clearFolderDialog {
    .dialogPaper {
        width: 400px;
        height: 192px;
    }
    .clearDialogTitle {
        padding: 24px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: rgba(0,0,0,.8);
    }
    .clearContent {
        padding: 0 24px;
        .clearContentText {
            padding: 0;
            font-family: 'PT Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #000000 !important;
        }
    }
    .clearFolderActions {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 36px 24px 28px 24px;
        .btn {
            display: flex;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.35px;
            color: #19252E;
        
            &.cancelClear {
                width: 97px;
                height: 32px;
                border: 1px solid rgba(25, 37, 46, 0.4);
                border-radius: 4px;
                text-transform: none !important;
            }
            &.clearButton {
                width: 97px;
                height: 32px;
                background: rgba(255, 150, 46, 0.72);
                border: 1px solid rgba(25, 37, 46, 0.08);
                border-radius: 4px;
                text-transform: none !important;
            }
        }
    }
}

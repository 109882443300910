.extended-reply {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	border-top: 1px solid rgba(22, 31, 41, 0.24);
	padding: 4px 12px 0 24px;

	&__label {
		font-family: Roboto;
		font-size: 16px;
		line-height: 24px;
		color: #596269;
		flex-shrink: 0;
		margin-right: 4px;
	}
	
	&__recipients {
		color: black;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-break: break-all;
		font-family: Roboto;
		font-size: 16px;
		line-height: 24px;
	}

	&__secure-field {
		display: flex;
		align-items: center;
		font-family: Roboto;
		font-weight: 500;
		font-size: 13px;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.8);
		background-color: rgb(244, 146, 0, 0.1);
	}
}
.TandISettings {
	height: auto;
	width: auto;

	.calendarTitle {
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 350px;
		white-space: nowrap;
	}
}

.TitleForTandI {
	height: 24px;
	margin-bottom: 10px;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: rgba(25, 37, 46, 0.72);
}

.InputWrapForTandI {
	display: flex;
	position: relative;
	flex-direction: column;

	input {
		border: 1px solid rgba(25, 37, 46, 0.08);
		border-radius: 4px;
		background-color: #ffffff;
		width: 374px;
		height: 32px;
		outline: none;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;

		&:disabled {
			width: 374px;
			height: 32px;
			outline: none;
			background: rgba(25, 37, 46, 0.08);
			border: 1px solid rgba(25, 37, 46, 0.08);
			border-radius: 4px;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			color: rgba(22, 31, 41, 0.6);
		}
	}
}

.settingsWrapper {
	height: 100%;
	width: 100%;
	margin-left: 32px;
	padding-top: 12px;

	.h-row {
		margin-bottom: 24px;
		display: flex;
		flex-direction: row;
		align-items: center;

		.MuiButtonBase-root {
			margin-right: 16px;
		}
	}
}

.settingsFooter {
	position: sticky;
	bottom: 0;
	background-color: white;
	width: 100%;
	height: 56px;
	display: flex;
	align-items: center;

	.saveButton {
		padding: 3px 12px;
		border-radius: 4px;
		border: 1px solid var(--Basic-Light-Fill-8, rgba(25, 37, 46, 0.08));
		background: var(--Mail-Fill-56, rgba(255, 150, 46, 0.56));
		font-family: Roboto;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0.35px;
		color: hsl(206, 30%, 14%);

		&:disabled {
			border: 1px solid var(--Basic-Light-Fill-8, rgba(25, 37, 46, 0.08));
			background: var(--Basic-Light-Fill-8, rgba(25, 37, 46, 0.08));
			color: var(--Basic-Light-Outline-40, #A3A8AB);
		}
	}

	.cancelSettings {
		color: hsl(206, 30%, 14%);
	}
}

.filtersHeadWrap {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 36px;

	.headHint {
		display: flex;
		flex-direction: column;

		.subSettingsHeader {
			height: 30px;
		}

		.subSettingsInfo {
			margin-top: 6px;
			height: 40px;
		}
	}
}

.subSettingsHeader {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	width: 100%;
	color: #000000;
}

.subSettingsHeaderSync {
	margin-bottom: 24px;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	width: 100%;
	color: #000000;
}

.saveSettings {
	position: relative;
	margin-top: 12px;
	display: inline-flex;
	height: 32px;
	margin-left: 29px;
	margin-top: 12px;
	cursor: pointer;
	background: #f58d00;
	border-radius: 4px;

	div {
		cursor: pointer;
		position: relative;
		display: inline-block;
		height: 20px;
		margin-right: 12px;
		margin-left: 12px;
		margin-top: 6px;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 20px;
		color: #ffffff;
	}
}

.resetSettings,
.cancelSettings {
	font-family: 'Roboto';
	display: inline-block;
	cursor: pointer;
	background-color: transparent;
	border: 1px solid hsla(204, 5%, 57%, 1);
	padding: 7px 11px;
	margin-left: 12px;
	border-radius: 4px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: rgba(22, 31, 41, 0.8);
	position: relative;
	height: 32px;
}

.resetSettings:focus,
.cancelSettings:focus {
	border: none !important;
	outline: none !important;
}

.settingsButton {
	display: inline-flex;
	height: 32px;
	border: 1px solid #f49200;
	color: #f49200;
	background: #ffffff;
	cursor: pointer !important;
	border-radius: 2px;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 20px;
	padding-left: 12px;
	padding-right: 12px;
	padding-top: 6px;
	padding-bottom: 6px;

	&.chooseCertificateButton {
		border: 1px solid #f49200;
		color: #ffffff;
		background: #f49200;
		font-family: Roboto !important;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 18px;
		color: #ffffff;

		&.changeCert {
			border: 1px solid rgba(22, 33, 41, 0.64);
			color: rgba(22, 33, 41, 0.64);
			background: #ffffff;
		}

		&.disableSecure {
			opacity: 0.5;
		}
	}

	&.cancelCertificateButton {
		color: rgba(22, 33, 41, 0.64);
		border-color: rgba(22, 33, 41, 0.64);
		margin-right: 10px;
	}
}

.subSettingsGrayTitle {
	margin-top: 24px;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: rgba(22, 31, 41, 0.6);
}

.settingsH3 {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	position: relative;
	font-size: 16px;
	line-height: 22px;
	width: fit-content;
	color: #000000;
}

.svgIcon {
	width: 24px;
	height: 24px;
	font-size: 24px;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}

.si-pencil {
	background-image: url('./assets/pencil.svg?inline');
}

.si-trash {
	background-image: url('../../assets/icons/trash.svg');
}

.si-inbox {
	background-image: url('../../assets/icons/inbox.svg');
}

.si-draft {
	background-image: url('../../assets/icons/draft.svg');
}

.si-flag {
	background-image: url('./assets/flag.svg?inline');
}

.si-folder {
	background-image: url('../../assets/icons/folder.svg');
}

.si-junk {
	background-image: url('../../assets/icons/junk.svg');
}

.si-sent {
	background-image: url('../../assets/icons/sent.svg');
}

.si-starred {
	background-image: url('../../assets/icons/starred.svg');
}

.si-switchoff {
	background-image: url('./assets/switchOff.svg?inline');
	width: 56px;
	height: 40px;
	font-size: 40px;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}

.si-switchon {
	background-image: url('./assets/switchOn.svg?inline');
	width: 56px;
	height: 40px;
	font-size: 40px;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}

.errorLineForTandI {
	height: 18px;
	position: relative;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: #e24141;
}

.saveButton {
	padding: 3px 12px;
	background: hsla(24, 100%, 83%, 1);
	border: 1px solid hsla(24, 100%, 76%, 1);
	/* Specific/button_normal */
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* 171.429% */
	letter-spacing: 0.35px;
	color: hsla(206, 30%, 14%, 1);
	border-radius: 4px;

	&:not(:disabled) {
		&:hover {
			background-color: #ec8000;
		}

		&:active {
			background: rgba(25, 37, 46, 0.08);
		}

		&:focus {
			background: rgba(25, 37, 46, 0.08);
		}
	}

	&:disabled {
		opacity: 50%;
		background: rgba(255, 150, 46, .56);
		border: 1px solid rgba(25, 37, 46, .08);
		color: #19252e;
	}
}

.deleteButton {
	height: 32px;
	padding-left: 12px;
	padding-right: 12px;
	background: #e24141;
	color: #ffffff;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	border-radius: 4px;
	border: none;
}

.svgAndName {
	padding: 4px 12px;
	width: fit-content;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	cursor: pointer !important;

	svg {
		width: 24px;
		height: 24px;
		position: relative;
		cursor: pointer !important;
	}

	div {
		margin-left: 8px;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 20px;
		color: #000000;
		cursor: pointer !important;
	}
}

.settings {
	&__header {
		color: #000;
		font-size: 19px;
		font-weight: 500;
		line-height: 24px;
		margin-bottom: 4px;
	}

	&__subheader {
		color: rgba(22, 31, 41, 0.6);
		font-size: 14px;
		line-height: 20px;
	}
}

.labels-list {
	max-width: 420px;
	width: 100%;

	&__item {
		height: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 4px;
		margin-bottom: 8px;
		padding: 4px 8px 4px 0;
		transition: background 0.2s linear;

		.labels-list__edit-button {
			display: none;
		}

		&:hover {
			background-color: rgba(25, 37, 46, 0.06);

			.labels-list__edit-button {
				display: inline-flex;
			}
		}
	}
}
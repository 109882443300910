.single-compose-wrap {
	height: 100%;
	width: 100%;
	background: rgba(22, 31, 41, 0.24);
	display: flex;

	.single-composer-body {
		overflow: hidden;
	}
	&.hidden {
		width: auto;
		height: 40px;
		position: absolute;
		bottom: 0;
		right: 16px;
		background-color: transparent;
		.single-composer-body {
			display: none;
		}
		.single-compose-footer {
			display: none;
		}
		.single-compose {
			min-width: 0;
			width: 315px;
			height: 40px;
		}
	}
}
.single-compose {
	height: 100%;
	width: 66.7%;
	min-width: 1040px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	.row {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
}
.single-composer-header {
	&.minimized {
		background: #2B363F;
		border-radius: 4px 4px 0 0;
		p {
			text-overflow: ellipsis;
			overflow: hidden;
			position: relative;
			font-family: Roboto !important;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			color: #FFFFFF;
		}
		& > .closeCompose {
			& > svg {
				& > path {
					fill: white;
				}
			}
		}
		& > .minimizeCompose {
			& > svg {
				transform: rotate(180deg);
				& > path {
					fill: white;
				}
			}
		}
	}
}

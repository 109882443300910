.messageComposerWrapper,
.single-compose {
	.detailsLineWrapper {
		display: flex;
		width: 100%;
		flex-direction: row;
		align-items: center;
		height: auto;
		position: relative;
		min-height: 40px;
		background-color: #ffffff;
		padding-left: 24px;

		.composer-autocomplete-input {
			&::-webkit-scrollbar {
				width: 6px !important;
				background-color: #ffffff !important;
			}

			&::-webkit-scrollbar-track {
				border: none !important;
				display: none;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 3px !important;
				background: rgba(0, 0, 0, 0.16) !important;
			}
		}
	}
	.detailLeftBlock {
		width: 600px;
		min-width: 600px;
		display: flex;
		flex-direction: row;
		align-items: center;

		.inputError {
			color: red;
			font-family: Roboto !important;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
		}
	}
	.fieldName {
		height: 24px;
		min-width: 40px;
		width: fit-content !important;
		block-size: fit-content;
		white-space: nowrap;
		display: inline-table;
		margin-right: 4px;
		font-family: Roboto !important;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		color: #596269;
	}
	.MuiAutocomplete-popper {
		z-index: 1000000000 !important;
	}
	.auto {
		height: auto;
		width: 100%;
		position: relative;
		overflow-x: hidden;

		.MuiFormControl-fullWidth {
			overflow-x: hidden;
		}
	}
	.MuiInput-underline {
		&:after {
			border-bottom: black 1px solid;
		}
	}
	.MuiAutocomplete-inputRoot[class*='MuiInput-root'] {
		.MuiAutocomplete-input {
			padding-bottom: 0px !important;
		}
	}
}
.auto {
	.optionAccountPill {
		margin-top: 2px !important;
	}
}
.messageComposerWrapper .auto .autoInputRootClass:before,
.messageComposerWrapper .auto .autoInputRootClass:after {
	border-bottom: 1px solid rgba(25, 37, 46, 0.4) !important;
}

.gray-text-button {
	padding: 4px 12px;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: #19252e;
}

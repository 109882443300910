.listbar {
	&__header-container {
		border-bottom: 1px solid rgb(232, 232, 232);
	}

	&__header {
		min-height: 48px;
		display: flex;
		flex-direction: row;
		padding: 4px 4px 4px 24px;
		color: #19252e;

		.layout__menu-btn {
			margin-right: 8px;
			margin-left: -2px;
		}

		.classic-checkbox {
			color: rgba(25, 37, 46, 0.56);
			padding: 0;
			height: 15px;
			width: 15px;
			border-radius: 4px;
			margin: 12px 12px 13px 0;
			flex-shrink: 0;

			.MuiSvgIcon-root {
				height: 21px;
				width: 21px;
			}

			&.checked {
				color: rgba(255, 179, 104);
				background-color: #19252e;

				&::after {
					background-color: #19252e;
					content: '';
					height: 15px;
					width: 15px;
					position: absolute;
					border-radius: 4px;
					z-index: -1;
				}

				.MuiIconButton-label:after {
					opacity: 1;
					background-color: '#19252E';
				}
			}
		}
	}

	&__mail-list-wrapper {
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;

		.mailItem {
			cursor: pointer;
		}
	}

	&__mail-list-body {
		height: calc(100vh - 55px);
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	&__footer {
		height: 55px;
		width: 100%;
	}

	&__empty-folder {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}
}

@media screen and (max-width: 639px) {
	.listbar__header {
		padding-right: 40px;
		padding-left: 16px;
	}
}

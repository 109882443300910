.single-compose {
	.single-compose-footer {
		width: 100%;
		height: 56px;
		background-color: #fff;
		border-top: 1px solid rgba(0, 0, 0, 0.16);
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 24px;
		padding-right: 32px;
		flex-shrink: 0;
	}

	.dropDownDivider {
		background: rgba(25, 37, 46, 0.16);
		height: 1px;
		margin-top: 4px;
		margin-bottom: 4px;
		width: 100%;
	}

	.profileWrap {
		cursor: pointer !important;
		margin-left: 16px;
		border: 1px solid rgba(25, 37, 46, 0.4);
		height: 32px;
		display: flex;
		flex-direction: row;
		align-items: center;
		border-radius: 4px;
		padding-left: 16px;
		padding-right: 12px;
	}

	#profileTitle {
		display: inline-block;
		height: 24px;
		margin-right: 4px;
		position: relative;
		cursor: pointer !important;
		font-family: 'Roboto' !important;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0.35px;
		color: #19252e;
	}

	.profileDropDownLine {
		max-width: 150px;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}

	.profileDropDown {
		width: 24px;
		display: flex !important;
		height: 24px;
		position: relative;

		button {
			width: 24px;
			height: 24px;
			border: none;

			a {
				color: #19252e;

				&:hover {
					text-decoration: none;
				}
			}
		}

		.dropdown-menu {
			width: 200px;
			padding: 8px 0px;
			height: auto;
			box-shadow: 0px 0px 8px rgba(25, 37, 46, 0.08), 0px 8px 8px -4px rgba(25, 37, 46, 0.08) !important;
			border-radius: 4px;
		}

		.signatureSwitcher {
			width: 30px;
			height: 20px;
		}

		.dropdown-item {
			display: flex;
			align-items: center;
			width: 100% !important;
			height: 40px !important;
			padding-left: 12px;
			font-family: 'PT Sans' !important;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: #000000;
		}

		.btn-secondary {
			display: flex !important;
			box-shadow: none !important;
			background-color: white !important;
			border: none !important;
			padding: 0;
		}
	}

	.sendButton {
		position: relative;
		cursor: pointer;
		height: 32px;
		padding: 4px 16px;
		background: rgba(255, 150, 46, 0.56);
		border: 1px solid rgba(25, 37, 46, 0.08);
		border-radius: 4px;
		font-family: Roboto !important;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		outline: none;
		letter-spacing: 0.35px;
		color: #19252e;

		&:disabled {
			opacity: 0.4;
			background-color: #A3A8AB;
			cursor: default;
		}
	}

	.saveDraft {
		height: 32px;
		background-color: #ffffff;
		padding: 4px 16px;
		outline: none;
		margin-left: 12px;
		cursor: pointer;
		border: 1px solid rgba(25, 37, 46, 0.4);
		box-sizing: border-box;
		border-radius: 4px;
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0.35px;
		color: #19252e;
	}

	.readingConfirmation {
		width: 32px;
		cursor: pointer;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 20px;
		position: relative;
		margin-left: 28px;

		&:hover {
			background-color: #edeeee;
		}

		&.active {
			background: rgba(255, 150, 46, 0.56);
		}
	}

	.readingConfirmationTitle {
		width: 84px;
		height: 36px;
		position: relative;
		margin-left: 8px;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 18px;
		color: rgba(0, 0, 0, 0.6);
	}

	.SensitivityDropDown {
		width: 24px;
		display: flex !important;
		height: 24px;
		position: relative;

		button {
			width: 24px;
			height: 24px;
			border: none;

			a {
				color: #19252e;

				&:hover {
					text-decoration: none;
				}
			}
		}

		.dropdown-menu {
			width: 199px !important;
			height: 176px !important;
			position: absolute;
			box-shadow: 0px 0px 8px rgba(25, 37, 46, 0.08), 0px 8px 8px -4px rgba(25, 37, 46, 0.08) !important;
			border-radius: 4px;
		}

		.dropdown-item {
			display: flex;
			align-items: center;
			width: 100% !important;
			height: 40px !important;
			padding-left: 12px;
			font-family: Roboto !important;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 20px;
			color: #19252e;
		}

		.btn-secondary {
			display: flex !important;
			padding: 0 !important;
			box-shadow: none !important;
			background-color: white !important;
			border: none !important;
			padding: 0;
		}

		&.noCertificate {
			.dropdown-menu {
				width: 390px !important;
				height: 216px !important;

				.hintSecure {
					&.dropdown-item {
						&:hover {
							background-color: rgb(235, 235, 235) !important;
							background-image: url('./../../../../assets/noCert.svg?inline') !important;
							background-repeat: no-repeat !important;
							background-position: 12px center !important;
							padding-left: 30px;
						}
					}
				}
			}

			.dropdown-item.disabled:nth-child(n + 2) {
				opacity: 0.5;
			}
		}
	}

	.dropdown-menu {
		.dropdown-item {
			&:hover {
				background: rgb(235, 235, 235) !important;
			}
		}
	}

	.sensitivityWrap {
		cursor: pointer !important;
		margin-left: 16px;
		border: 1px solid rgba(25, 37, 46, 0.4);
		height: 32px;
		display: flex;
		flex-direction: row;
		align-items: center;
		border-radius: 4px;
		padding-left: 16px;
		padding-right: 12px;
	}

	.dropup {
		.dropdown-toggle {
			&::after {
				display: none;
			}
		}
	}

	#securityTitleWrapper {
		border: 1px solid rgba(25, 37, 46, 0.4);
		height: 32px;
		display: flex;
		flex-direction: row;
		align-items: center;
		border-radius: 4px;
		padding-left: 16px;
		padding-right: 12px;
		margin-left: 16px;
	}

	.noCertificate {
		.hintSecure {
			background: rgb(244 146 0 / 10%);
			background-image: url('./../../../../assets/noCert.svg?inline');
			background-repeat: no-repeat;
			background-position: 12px center;
			padding-left: 30px;
		}
	}

	.sensitivityTitle,
	#securityTitle {
		display: inline-block;
		height: 24px;
		position: relative;
		cursor: pointer !important;
		font-family: Roboto !important;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0.35px;
		color: #19252e;
	}

	.SensitivityDropDown .btn-secondary.focus,
	.btn-secondary:focus {
		box-shadow: none !important;
		background-color: white !important;
		border: none !important;
	}
}

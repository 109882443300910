#warningModal {
  display: flex;
  justify-content: center;
  align-items: center;

  .warningBody {
    width: 400px;
    height: 190px;
    border-radius: 8px;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 16px 24px 32px 24px;
    background-color: white;

    .warningHeader {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex: 1;

      h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 24px;
      }
    }
  }
  .warningFooter {
    align-self: flex-end;

    .button {
      margin-right: 12px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.35px;
      color: black;

      &.closeSettings {
        padding: 5px 15px;
        background: rgba(255, 150, 46, 0.56);
        border: 1px solid rgba(25, 37, 46, 0.08);
        border-radius: 4px;

        &:hover {
          background: rgba(255, 150, 46, 0.72);
        }
        &:active {
          background: rgba(255, 150, 46, 0.88);
        }
      }
    }
  }
}


.messageComposerWrapper {
	#messageDetails {
		width: 100%;
		height: auto;
		position: relative;
		top: 0px;
		left: 0px;
		background: #ffffff;
		padding-bottom: 4px;
	}
}
.securityLine {
	padding: 12px 24px;
	font-size: 14px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.8);
	display: flex;
	flex-direction: row;
	&.securityLineHdden {
		height: 0;
		min-height: 0px;
		opacity: 0;
		padding: 0;
		margin: 0;
	}
	&.securityLineVisible {
		min-height: 44px;
		opacity: 1;
		transition: opacity 150ms ease 0s, min-height 150ms ease 0s;
	}
	div {
		margin-right: 8px;
	}
	.icon {
		padding-left: 24px;
		background-position: 0px -2px !important;
		&.sign {
			background: url('./../../../../../../assets/sign.svg?inline') no-repeat;
		}
		&.encrypt {
			background: url('./../../../../../../assets/encrypt.svg?inline') no-repeat;
		}
	}
}

.mail-label-chip {
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 4px;
  background-color: #F6F6F7;
  flex-grow: 1;

  .MuiChip-label {
    padding-left: 6px;
    flex-grow: 1;
    
    .MuiTypography-root {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
    margin: 0;
  }

  svg {
    flex-shrink: 0;
  }
}
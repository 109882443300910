.securitySubSettings {
    width: 100%;
    max-width: 640px;
    height: 100%;
    width: 100%;
    display: flex;
    max-width: 640px;
    flex-direction: column;
}

.securityWrap {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 15px;

    .subSettingsNotificationsCheckbox {
        margin-top: 0px;
    }
}

.userCertificateSet {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
    margin: 17px 0;

    .userCertificateSetTitle {
        font-weight: bold;
    }
}

.pluginError {
    width: 560px;
    background: rgba(226, 65, 65, 0.16);
    border-radius: 2px;
    font-family: Roboto !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.8);
    padding: 10px 14px;
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    &.cancelAction {
        background: rgba(255, 125, 0, 0.16);
        background: rgba(255, 125, 0, 0.16);
    }

    .pluginErrorIcon {
        background: url('../../../../../assets/noCert.svg?inline') no-repeat;
        width: 30px;
        height: 16px;
    }

    ul {
        padding: 0;
        margin-bottom: 15px;

        /*text-indent: 10px;*/
        li {
            list-style: none;
        }
    }

    &.noCert {
        background: rgba(255, 125, 0, 0.16);
        margin-top: 10px;

        .pluginErrorIcon {
            background: url('../../../../../assets/noCert.svg?inline') no-repeat;
            width: 18px;
            min-width: 18px;
        }
    }
}

.certificateList {
    padding: 0px 15px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;

    .certificateListItem {
        display: flex;
        flex-direction: row;
        min-height: 24px;
        margin-bottom: 16px;

        .certificateListRadioButton {
            cursor: pointer;
            width: 25px;
            margin-right: 7px;
        }

        .certificateListInfo {
            cursor: pointer;
            display: flex;
            flex-direction: column;
        }
    }
}

.certificateListButton {
    float: right;
    margin: 0 15px;
}

.chooseCertificateTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
    padding: 12px 15px;
}

.chooseCertificate {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
    padding: 12px 15px;
}

.subSettingsNotificationsCheckbox.disableSecure,
.subSettingsNotificationsHint.disableSecure {
    opacity: 0.5;
    pointer-events: none;
    cursor: default !important;
}
.change-password-dialog{
	width: 444px;
	min-height: 520px;
	display: flex;
	flex-direction: column;
	position: relative;

	h2 {
		line-height: 24px;
		color: hsla(206, 30%, 14%, 1)
	}

	.dialog-header{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		.closeInstructionsButton{
			margin-right: 0.5rem;
		}
	}

	.loading-screen{
		position: absolute;
		background-color: hsla(0, 0%, 100%, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
	}

	.dialog-body{
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 8px 24px;
		flex: 1 auto;

		&_reminder{
			letter-spacing: .35px;
			font-size: 12px;
		}
	}

	.dialog-footer{
		padding: 24px;
		display: flex;
		flex-direction: row;
		gap: 12px;
	}
}
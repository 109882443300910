body {
	margin: 0;
	background: #ffffff !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.z-alert {
	z-index: 11000 !important;
}

@font-face {
	font-family: 'text-security-disc';
	src: url('./assets/fonts/text-security-disc.woff2') format('woff2');
}

button:focus {
	outline: none;
}

.rotate {
	transform: rotate(90deg);
}

.inverted {
	transform: rotate(180deg);
}

.custom-scrollbars {
	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-track {
		box-shadow: unset;
		background-color: white;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, 0.16);
		&:hover {
			background: rgba(25, 37, 46, 0.16);
		}
	}
}

.mail-button {
	padding: 3px 12px;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: #19252e;
	border: 1px solid rgba(25, 37, 46, 0.08);
	background-color: rgba(255, 150, 46, 0.56);

	&:hover {
		background-color: rgba(255, 150, 46, 0.4);
		box-shadow: none
	}

	&:disabled {
		background-color: rgba(255, 150, 46, 0.4);
		color: rgb(25 37 46 / 40%);
	}
}

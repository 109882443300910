.secureModal {
	padding: 16px 12px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: #000000;
	.title {
		margin-bottom: 16px;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.8);
		font-size: 20px;
	}
	.more {
		color: #f58d00;
		cursor: pointer;
	}
	.list {
		overflow-x: hidden;
		overflow-y: auto;
		height: 184px;
		max-height: 184px;
	}
	.button {
		display: flex;
		flex-direction: row;
		margin-top: 16px;
		justify-content: flex-end;
		div {
			border-radius: 4px;
			font-family: Roboto;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 28px;
			cursor: pointer;
			height: 32px;
			padding: 0 12px;
		}
		.close {
			color: rgba(22, 33, 41, 0.64);
			border: 1px solid rgba(22, 33, 41, 0.64);
			margin-right: 12px;
			opacity: 1;
		}
		.exclude {
			color: #ffffff;
			background: #f58d00;
			border: 1px solid #f58d00;
		}
	}
}
.secureModal .button .exclude a,
.secureModal .button .exclude a:hover {
	color: #ffffff;
	text-decoration: none;
}

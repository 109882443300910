.optionAccountPill {
  display: flex;
  flex-direction: row;
  cursor: pointer !important;
  position: relative;
  height: 24px !important;
  border: none !important;
  margin-left: 8px;
  background: #dadcde !important;
  border-radius: 12px;
  box-sizing: content-box;
  flex: none;
  order: 0;
}
.accountPillAvatar {
  width: 24px;
  height: 24px;
  cursor: pointer !important;
  border-radius: 12px;
  position: absolute;
}
.accountPillName {
  position: relative;
  margin-left: 28px;
  cursor: pointer !important;
  height: 24px;
  display: inline-block;
  vertical-align: baseline;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 24px;
  color: #19252e;
  overflow: hidden;
  min-width: min-content;
  white-space: nowrap;
}
.deletePill {
  width: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;
  position: relative;
  svg {
    position: relative;
  }
}
#editableAccountPill {
  height: 24px;
  resize: none;
  overflow: hidden;
  margin-top: 3px;
  padding: 0;
  line-height: 24px;
  min-width: 0px;
  border: none;
  width: fit-content;
}

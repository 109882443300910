.contact-popover {
	z-index: 1300;

	.contact-popover {
		&__paper {
			width: 320px;
			max-height: 600px;
			color: #19252e;
			font-family: 'Roboto';
		}
		&__container {
			padding: 12px 12px 16px 12px;
			display: flex;
			align-items: stretch;
			width: inherit;
		}
		&__body {
			word-break: break-word;
		}
		&__avatar {
			margin-right: 12px;
			position: sticky;
			top: 12px;
		}
		&__header {
			min-height: 40px;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.name {
				font-weight: 500;
				font-size: 16px;
				line-height: 20px;
			}
			.job-title,
			.email {
				font-size: 12px;
				line-height: 14px;
			}
		}

		&__title {
			font-family: Roboto;
			font-size: 14px;
			font-weight: 700;
			line-height: 16px;
			padding: 8px 0;
		}

		&__subtitle {
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;
			letter-spacing: 0.35px;
			color: #596269;
		}
		&__value {
			font-size: 14px;
			line-height: 24px;
		}
	}

	.flex-container {
		display: block;
	}
}

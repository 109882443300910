@import "/src/assets/variables.scss";

#fromLineComposer {
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 100%;
  padding-left: 24px;
  align-items: center;

  &>p {
    min-width: 40px;
    width: fit-content !important;
    block-size: fit-content;
    white-space: nowrap;
    display: inline-table;
    margin: 0;
    margin-right: 4px;
    font-family: Roboto !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #596269;
  }

  .accounts-dropdown {
    width: max-content;

    &>div {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      &>p {
        margin: 0;
        font-family: PT Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #000000;

        &>span {
          color: #596269;
          font-family: PT Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
}

ul.from-list-option__aliases-container {
  box-shadow: none;
  border-radius: 0%;
  border-bottom: 1px solid $light-grey;
}

#from-popper {
  z-index: 10025;
  margin-left: 52px;
  background: #ffffff;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08));
  //For FF
  scrollbar-width: thin;
  scrollbar-color: $light-grey transparent;
}

//For Chrome
#from-popper::-webkit-scrollbar-thumb {
  background-color: $light-grey;
  width: 4px;
  border-radius: 2px;
}

.from-list {
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
  padding: 0px;
  width: 320px;
  box-shadow: 0px 0px 8px rgba(25, 37, 46, 0.08),
    0px 8px 8px -4px rgba(25, 37, 46, 0.08);
  border-radius: 4px;

  &>li {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 52px;
    padding-left: 12px;

    &.active {
      background: rgba(255, 150, 46, 0.56) !important;
    }

    &:hover {
      background: rgba(22, 31, 41, 0.08);
    }

    &>img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    &>div {
      margin-left: 12px;
      height: 44px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      overflow: hidden;

      &>p {
        margin: 0;
        cursor: default;
        font-family: PT Sans;
        font-style: normal;
        font-weight: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:nth-of-type(1) {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: #19252E;
        }

        &:nth-of-type(2) {
          font-size: 14px;
          line-height: 20px;
          color: rgba(22, 33, 41, 0.64);
        }
      }
    }
  }
}
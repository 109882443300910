.userLineRecall {
	width: 100%;
	height: 24px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 15px;
}
.userLineText {
	max-width: 239px;
	overflow: hidden;
	margin-left: 8px;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #000000;
}

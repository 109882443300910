
.Button{
	padding: 4px 16px;
	line-height: 24px;
	border-radius: 4px;
	border: 1px solid;
	font-size: 14px;
	color: hsla(206, 30%, 14%, 1);

	&.accent-mail{
		background-color:hsla(30, 100%, 59%, 0.56);
		border-color: hsla(206, 30%, 14%, 0.08);
		

		&:not(:disabled){
			&:hover{
				background-color: hsla(30, 100%, 59%, 0.72);
			}
		}
	}


	&.outlined{
		border-color:hsla(206, 30%, 14%, 0.4) !important;

		&:not(:disabled){
			&:hover{
			background-color:hsla(206, 30%, 14%, 0.08);
			border-color:hsla(206, 30%, 14%, 0.08);
		}
	}
	}

	&:disabled{
		background-color:  hsla(206, 30%, 14%, 0.08) !important;
	}
}


.mail-toolbar {
	width: 100%;
	padding: 4px 24px 4px 8px;

	&__right-panel {
		margin-left: auto;
	}

	.mail-toolbar {
		&__button {
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: 0.35px;
			color: #19252e;
			padding: 4px 12px;

			&.Mui-disabled {
				color: rgba(0, 0, 0, 0.26);
			}
		}

		&__start-icon {
			margin-right: 4px;

			> svg {
				font-size: 24px;
			}
		}
	}
}

@media screen and (max-width: 639px) {
	.mail-toolbar {
		padding: 4px 8px;
	}
}
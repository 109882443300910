.mail-labels-chips-list {
  margin-top: 8px;
  align-items: center;

  .mail-labels-chips-list__more-button {
    color: #19252E;
    background-color: #F6F6F7;
    min-width: auto;
    padding-left: 12px;
    padding-right: 12px;
  }
}
.sensitivityNotification {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	p {
		font-family: PT Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: #000000;
		margin-top: 80px;
		margin-bottom: 16px;
	}
	button {
		background: rgba(245, 141, 0, 0.6);
		border: 0.5px solid rgba(223, 128, 0, 0.28);
		box-sizing: border-box;
		border-radius: 4px;
		padding: 4px 20px;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: rgba(22, 33, 41, 0.92);
		&:focus {
			outline: none;
		}
	}
}

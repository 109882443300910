.main-wrapper{
    height: 100vh;
    position: relative;
    
    .viewer-container{
        height: 100%;
        .doc-view, .doc-view-view{
            height: 100%;
        }
    }
}
.minimizedComposer {
	width: 315px;
	height: 40px;
	background: #2B363F;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 8px;
	padding-right: 8px;
	box-shadow: 0px 0px 8px rgba(25, 37, 46, 0.08), 0px 8px 8px -4px rgba(25, 37, 46, 0.08);
	border-radius: 4px 4px 0px 0px;
	
	#close {
		cursor: pointer !important;
		width: 24px;
		height: 24px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	#reopen-padding {
		cursor: pointer !important;
		margin-right: 16px;
		height: 24px;
		width: 24px;
		margin-left: auto;
		cursor: pointer;
	}
	#name {
		height: 24px;
		max-width: 207px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		position: relative;
		font-family: Roboto !important;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		color: #FFFFFF;
	}

	svg {
		position: absolute;
	}	
}

.single-view-subject {
	width: 100%;
	min-height: auto;
	height: auto;
	padding: 16px 24px;
	background-color: #fff;
	position: sticky;
	top: 0;
	
	& > p {
		font-family: Roboto !important;
		font-style: normal;
		font-weight: 500;
		font-size: 19px;
		line-height: 24px;
		color: rgba(22, 33, 41, 0.92);
		margin: 0;
	}
}
.single-view-recipients {
	width: 100%;
	min-height: auto;
	margin-top: 8px;
	display: flex;
	flex-direction: column;
	padding-left: 24px;
	padding-right: 24px;
	
	.recipients-row {
		height: auto;
		min-height: 32px;
		display: flex;
		flex-direction: row;
		& > div {
			&:nth-child(1) {
				position: relative;
				width: 32px;
				min-width: 32px;
				display: inline-block;
				align-items: center;
				height: 32px;
				display: flex;
				min-width: max-content;
				& > p {
					font-family: Roboto !important;
					font-style: normal !important;
					font-weight: normal !important;
					font-size: 14px;
					line-height: 24px;
					color: #596269;
					margin: 0;
				}
			}
			&:nth-child(2) {
				height: auto;
				display: flex;
				padding-top: 4px;
				flex-wrap: wrap;
			}
			&:nth-child(3) {
				display: flex;
				flex-direction: row;
				align-items: center;
				height: 32px;
				margin-left: auto;
				min-width: fit-content;
				& > p {
					height: 24px;
					font-family: Roboto !important;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 24px;
					color: #000000;
					text-align: right;
					margin: 0;
					margin-right: 8px;
				}
			}
		}
		svg {
			position: relative !important;
		}
	}
}

.mailViewOptionBody {
	height: auto;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}
#mailViewSecurityPanel {
	display: flex;
	flex-direction: row;
	margin: 0 24px;
	margin-top: 12px;
	font-family: PT Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.6);
	.securityPillChips {
		position: relative;
	}
	.icon {
		padding-left: 20px;
		background-repeat: no-repeat;
		background-position: -5px -1px;
		margin-left: 10px;
		cursor: pointer;
		&.encrypt {
			background-image: url('./../../../../assets/encrypt.svg?inline');
		}
		&.decrypted {
			background-image: url('./../../../../assets/decrypted.svg?inline');
			background-position: 0px;
		}
		&.decryptedFail {
			background-image: url('./../../../../assets/decryptedFail.svg?inline');
			background-position: 0px;
		}
		&.verifyProcess {
			background-image: url('./../../../../assets/sign.svg?inline');
		}
		&.verified {
			background-image: url('./../../../../assets/verified.svg?inline');
			background-position: 0px;
		}
		&.verifiedFail {
			background-image: url('./../../../../assets/verifiedFail.svg?inline');
			background-position: 0px;
		}
	}
}

.Instructions {
	height: 100%;
	width: 100%;
	.subHeader {
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 8px;
		margin-top: 16px;
	}
	.knowledge-base {
		height: 32px;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-top: 4px;
		padding-bottom: 4px;
		border-radius: 4px;
		padding-left: 12px;
		padding-right: 12px;
		border: 1px solid rgba(25, 37, 46, 0.4);
		background-color: #ffffff;
		&:hover {
			background-color:rgba(25, 37, 46, 0.08);;
		}

		a {
			font-family: Roboto;
			text-decoration: none;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 24px;
			color: #19252E;
			margin-right: 8px;
		}
	}
	
}

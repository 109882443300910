.filterLine {
	padding-right: 72px;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	min-height: 40px;
	width: 100%;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	border-radius: 4px;
	padding-left: 4px;
	cursor: grab;
	gap: 4px;

	.si-tripleDot {
		position: absolute;
		left: 12px;
		top: 8px;
	}

	.si-switchoff {
		position: absolute;
		left: 0;
		top: 0;
	}

	.si-switchon {
		position: absolute;
		left: 0;
		top: 0;
	}


}

.filterTrash {
	position: absolute;
	right: 12px;
	cursor: pointer;
}

.filterPencil {
	position: absolute;
	right: 48px;
	cursor: pointer;
}

.boldTextFilter {
	cursor: grab !important;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
}


.defaultTextFilter {
	cursor: grab !important;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.filterIconWrapper {
	align-items: center;
	display: flex;
	height: 40px;
	cursor: pointer;
	top: 0;
	left: 0;
}
.SmallNotification {
	max-width: 582px;
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 10px;
	box-shadow: 0 0 8px rgba(25, 37, 46, 0.08), 0 8px 8px -4px rgba(25, 37, 46, 0.08);
	border-radius: 4px;
	& .close {
		margin-left: 10px;
	}
}
.SmallNotificationWrapper {
	display: flex;
	flex-direction: row;
}
.SmallNotificationIcon {
	width: 24px;
	height: 24px;
	position: relative;
	img {
		position: relative;
		top: -1px;
	}

	&.close {
		&:hover {
			cursor: pointer;
		}
	}
}
.SmallNotificationAction {
	margin-top: 20px;
	border: solid 1px;
	background: none;
	border-radius: 4px;
	align-self: flex-end;
	justify-content: center;
	align-items: center;
}
.SmallNotificationBody {
	margin-left: 5px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	word-break: break-word;
	color: #000000;
}

.skeleton-mail-item {
	width: 100%;
	height: 64px;
	display: flex;
	flex-direction: row;
	background: #ffffff;
	position: relative;
	border-bottom: 1px solid #e8e8e8;
	& > div {
		&:first-of-type {
			display: flex;
			flex-direction: row;
			padding-top: 10px;
			padding-left: 16px;
		}
		&:nth-of-type(2) {
			display: flex;
			padding-left: 8px;
			padding-top: 8px;
			flex-direction: column;
			flex-grow: 1;
			& > div {
				display: flex;
				flex-direction: row;
				padding-right: 8px;
			}
		}
	}
}

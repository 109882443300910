#mailSettings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  .settingsDialogContainer {
    height: 100% !important;
    margin: auto !important;
    width: auto !important;

    .settingsHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px 16px 0 16px;
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;

      .closeSettingsButton {
        padding: 0;
        align-self: baseline;
      }
    }
  }

  .settingsDialogFooter {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 16px;
    height: 57px;
    background: white;

    .button {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.35px;
      color: #19252E;

      &.cancel {
        margin-left: 12px;
        margin-top: 0;
        border: 1px solid rgba(25, 37, 46, 0.4);
      }

      &.save {
        background: rgba(255, 150, 46, 0.56);

        &:hover {
          background: rgba(255, 150, 46, 0.72);
        }
        &:active {
          background: rgba(255, 150, 46, 0.88);
        }
        &:disabled {
          background: rgba(25, 37, 46, 0.08);
          color: #A3A8AB;
        }
      }
    }
  }

  .settingsWrap {
    padding: 10px 0px 12px 16px;
  }

  .settingsPaper {
    overflow: hidden;
    min-height: 400px;
    min-width: 200px;
    border-radius: 8px !important;

    .settingsBody {
      padding-right: 11px;
      overflow-y: scroll;
    }

    .settingsBody::-webkit-scrollbar {
      width: 6px;
    }
    
    .settingsBody::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0);
    }
    
    .settingsBody::-webkit-scrollbar-thumb {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 20px;
    }
  }

  h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 24px;
  }

  h2 {
    margin-top: 18px;
    margin-bottom: 18px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .mainSettings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .useSslCheck {
    margin-top: 24px;
    display: flex;

    label {
      margin-bottom: 0;
      margin-left: 8px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .sslLabel {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }

  .sslBox {
    padding: 0px !important;
  }

  .optionsRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;

    &.sslCheck, &.sslList {
      flex-direction: row;

      .optionsInput {
        width: 150px;
      }
    }

    &.sslList {
      .optionsInput {
        margin-right: 8px !important;
      }

      .sslSelect {
        padding: 8px 16px;
        width: 100%;
      }
    }

    .sslChecked {
      background: rgba(255, 150, 46, 0.56);
    }

    .optionsSubRow {
      display: flex;
      flex-direction: column;
    }

    label {
      margin-bottom: 8px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: rgba(25, 37, 46, 0.72);
    }

    .optionsInput {
      width: 343px;
      height: 32px;
      border-color: rgba(25, 37, 46, 0.4);
      border-radius: 4px;

      &.Mui-focused fieldset {
        border-color: black;
      }

      .inputButton {
        .internalEmailInfo {
          padding: 0;
        }
        .openEyeIcon, .closedEyeIcon {
          padding: 3px;
        }
      }
    }

    input[type="password"] {
      padding-top: 8px !important;
    }

    .textLine {
      padding: 7px 12px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #2B363F;
      &.hide {
        font-family: text-security-disc !important;
        font-size: 14px !important;
        -webkit-text-security: disc;
        -moz-text-security: disc;
      }
    }

    .sslOption {
      display: flex;
      align-self: flex-end;

      .sslBox {
        margin: 14px 14px 4px 14px;
      }

      label {
        margin-bottom: 4px;
        align-self: end;
      }
    }
    .wrongEmail, .nameError{
      margin-left: 12px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #D43A3E;
    }
  }

  .notification {
    display: flex;
    flex-direction: row;

    .notificationIcon {
      position: relative;
      width: 16px;
      height: 16px;
      margin-right: 12px;
      margin-top: 4px;
    }

    .notificationText {
      width: 312px;
      height: 42px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.35px;
    }
  }
  .loaderWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 30%);
    z-index: 10;
  }

  #ssl1 {
    top: unset !important;
    left: unset !important;
    width: 24px !important;
    height: 24px !important;
  }
}
.mailTooltipInfo {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  border: none;
  border-radius: 0;
}
.crop-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 80px;
}
.controls {
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 100%;
	transform: translateX(-50%);
	height: 80px;
	display: flex;
	align-items: center;
}
.slider {
	padding: 22px 0px;
}
.croppie {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.MuiSlider-root {
	color: #FBDAAD !important;
	height: 4px !important;
	-webkit-tap-highlight-color: initial !important;
	opacity: 1 !important;
}
.MuiSlider-rail {
	height: 4px !important;
	opacity: 0.25 !important;
	-webkit-tap-highlight-color: initial !important;
	background-color: gray;
}
.MuiSlider-track {
	opacity: 1 !important;
	height: 4px !important;
}

.LinkPopUpFooter {
	width: 100%;
	height: 56px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding-top: 12px;
	padding-bottom: 12px;
	button {
		padding: 12px 4px;
		font-family: Roboto;
		box-sizing: border-box;
		border-radius: 4px;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 24px;
		height: 32px !important;
		width: max-content !important;
		&:nth-child(1) {
			border: 1px solid rgba(22, 33, 41, 0.28) !important;
			padding: 12px 4px;
			color: rgba(22, 33, 41, 0.64) !important;
			background-color: #ffffff;
		}
		&:focus {
			outline: none !important;
		}
		&:nth-child(2) {
			background: rgba(245, 141, 0, 1) !important;
			border: none;
			margin-left: 12px;
			color: #FFFFFF !important;
		}
		&:nth-child(2):disabled {
			opacity: 0.5 !important;
		}
	}
	#LinkPopUpCancel {
		padding: 4px 12px !important;
	}
	#LinkPopUpInsert {
		padding: 4px 12px !important;
	}
}

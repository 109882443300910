.LinkPopUp {
	width: 403px;
	height: 222px;
	padding-top: 16px;
	background: #FFFFFF;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 4px 8px -4px rgba(25, 37, 46, 0.36), 0px 8px 8px -2px rgba(25, 37, 46, 0.16);
	filter: drop-shadow(0px 0px 8px rgba(25, 37, 46, 0.12));
	position: absolute;
	top: 40px;
	right: 0px;
	padding-left: 12px;
	padding-right: 12px;
	.LinkPopUpLine {
		&:nth-child(1) {
			margin-bottom: 14px;
		}
		&:nth-child(2) {
			margin-bottom: 22px;
		}
	}
}

#securityPill {
	position: absolute;
	width: 400px;
	min-height: 292px;
	background: #FFFFFF;
	border-radius: 2px;
	z-index: 1001;
	box-shadow: 0px 1px 20px 2px rgba(0, 0, 0, 0.2);
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: rgba(0, 0, 0, 0.8);
	padding: 16px 12px;
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	div {
		&:nth-child(2) {
			margin: 16px 0;
		}
	}
	.securityPillTitle {
		font-weight: bold;
		font-size: 16px;
		color: #162129;
	}
	.securityPillName {
		font-weight: bold;
	}
	.securityPillDownload {
		cursor: pointer !important;
		font-family: PT Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 20px;
		color: #F49200;
	}
}

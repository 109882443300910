.colorPallete {
	width: 200px;
	height: 227px;
	background-color: #ffffff;
}

.colorPalleteName {
	display: inline-block;
	height: 24px;
	position: relative;
	margin-top: 8px;
	margin-left: 12px;

	
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */

	color: #000000;
}

.colors {
	width: 174px;
	height: 174px;
	position: relative;
	display: flex;
	flex-direction: column;
	margin-top: 5px;
	margin-left: 12px;
}

.color {
	width: 20px;
	height: 20px;
	position: relative;
	cursor: pointer;
	margin-right: 2px;
}

.colorLine {
	display: flex;
	width: 174px;
	height: 20px;
	margin-bottom: 2px;
	position: relative;
}
